import "./papertrader-control.scss";
import "react-circular-progressbar/dist/styles.css";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { PapertraderService } from "../../../../services/PapertraderService";
import { PapertraderSeasonsMenu } from "../../../paperwallet-center/PapertraderSeasonsMenu";
import { useAuth0 } from "@auth0/auth0-react";
import {
  GetPapertraderSeasonContext,
  SetPapertraderSeasonContext,
} from "../../../../pages/papertrader-page/PapertraderPage";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { getTheme } from "../../../../utils/themeUtil";
import CountUp from "react-countup";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Tooltip } from "@mantine/core";
import {
  getPapertraderSeasonStartPadding,
  toTimestring,
} from "../../../../utils/FormattingUtils";

interface Props {
  activeTheme: string;
}
export function PapertraderControl(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const selectedSeasonKey = useContext(GetPapertraderSeasonContext);
  const setSelectedSeasonKey = useContext(SetPapertraderSeasonContext);

  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  const getSeasons = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await PapertraderService.blueprint.getSeasons(token);
      return response.data;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const seasonsQuery = useQuery(["Seasons"], getSeasons, {
    cacheTime: 0,
    staleTime: 120000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!selectedSeasonKey && setSelectedSeasonKey && seasonsQuery.data) {
      setSelectedSeasonKey(seasonsQuery.data.current_season);
    }
  }, [setSelectedSeasonKey, selectedSeasonKey, seasonsQuery.data]);

  const percentage = useMemo(() => {
    if (!seasonsQuery.data || !selectedSeasonKey) return 100;
    if (selectedSeasonKey !== seasonsQuery.data.current_season) return 100;
    const now = new Date();

    const currentSeasonTimestamos =
      seasonsQuery.data.season_timestamps[seasonsQuery.data.current_season];

    const seasonStart = new Date(currentSeasonTimestamos[0]);
    const seasonEnd = new Date(currentSeasonTimestamos[1]);
    const seasonDuration = seasonEnd.getTime() - seasonStart.getTime();
    const timePassed = now.getTime() - seasonStart.getTime();
    return (timePassed / seasonDuration) * 100;
  }, [selectedSeasonKey, seasonsQuery.data]);

  const selectedSeasonTooltipLabel = useMemo(() => {
    if (!seasonsQuery.data || !selectedSeasonKey) return undefined;
    const selectedSeasonTimestamps =
      seasonsQuery.data.season_timestamps[selectedSeasonKey];
    const seasonStart = toTimestring(
      selectedSeasonTimestamps[0] - getPapertraderSeasonStartPadding(),
      "1D"
    );
    const seasonEnd = toTimestring(selectedSeasonTimestamps[1], "1D");
    const headerMessage =
      selectedSeasonKey === seasonsQuery.data.current_season
        ? "This season is underway"
        : "This season has ended";

    return (
      <div className="papertrader-season-tooltip-container">
        <label className="papertrader-season-tooltip-header">
          {headerMessage}
        </label>
        <label>
          start: <strong>{seasonStart}</strong>
        </label>
        <label>
          end: <strong>{seasonEnd}</strong>
        </label>
      </div>
    );
  }, [selectedSeasonKey, seasonsQuery.data]);

  return (
    <div className="common-control-column">
      <label className="common-control-column-header">Papertrader</label>
      <div className="papertrader-duration-container">
        <Tooltip
          className="circular-progress-container"
          label={selectedSeasonTooltipLabel}
          position="left"
        >
          <CircularProgressbarWithChildren
            value={percentage}
            styles={buildStyles({
              strokeLinecap: theme.card,
              trailColor: theme.cardInterior,
              pathColor: theme.cardInteriorHover,
            })}
            children={
              percentage === 100 ? (
                <IoIosCheckmarkCircle
                  size={50}
                  color={theme.cardInteriorHover}
                />
              ) : (
                <CountUp
                  className={"wallet-stat-label papertrader-duration-label"}
                  end={percentage}
                  preserveValue
                  start={0}
                  duration={1}
                  suffix="%"
                  decimals={1}
                />
              )
            }
          />
        </Tooltip>
      </div>
      {seasonsQuery.data && (
        <PapertraderSeasonsMenu
          activeTheme={props.activeTheme}
          currentSeasonKey={seasonsQuery.data.current_season}
          seasonTimestamps={seasonsQuery.data.season_timestamps}
          selectedSeasonKey={selectedSeasonKey}
          setSelectedSeasonKey={(_seasonKey: string) => {
            setSelectedSeasonKey && setSelectedSeasonKey(_seasonKey);
          }}
        />
      )}
    </div>
  );
}
