import "./common-trading-card.scss";
import { Currency } from "../../../interfaces/Currency";
import { CurrencyPriceData } from "../../../interfaces/PricesOverview";
import { CommonWalletReport } from "../../../interfaces/common-trading/CommonTradingReport";
import { ActionIndicator } from "../../backtest-center/currency-overview/backtest-trading-card/ActionIndicator";
import { Avatar, Tooltip } from "@mantine/core";
import {
  Suspense,
  lazy,
  memo,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CandleSizeContext,
  FocusedOrderEntryContext,
  SetFocusedOrderEntryContext,
  SetOrderModalOpenContext,
} from "../../../pages/common/TradingDashboard";
import { CgArrowsExchange, CgDetailsMore } from "react-icons/cg";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { CiZoomOut } from "react-icons/ci";
import { MdCandlestickChart } from "react-icons/md";
import { usePalette } from "react-palette";
import { getTheme } from "../../../utils/themeUtil";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import {
  calcPctChange,
  determineDecimals,
} from "../../../utils/FormattingUtils";
import CountUp from "react-countup";
import { PiChartLineUp, PiChartLineDown } from "react-icons/pi";
import { motion } from "framer-motion";
import { IsBacktestingContext } from "../../../pages/backtester-page/BacktesterPage";
import { NeotonSpinner } from "../../custom-loaders/PixelLoader";
import { OrderInspect } from "../order-inspect/OrderInspect";
import { FiX } from "react-icons/fi";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { FaMagnifyingGlassDollar } from "react-icons/fa6";

interface Props {
  currency: Currency;
  isHolding: boolean;
  activeTheme: string;
  isLoading: boolean;
  priceData: CurrencyPriceData | undefined;
  commonWalletReport: CommonWalletReport | undefined;
  setFocusedCurrency: (currency: Currency | undefined) => void;
}

const TradingCardChart = lazy(
  () =>
    import(
      "../../chart-components/interactive-chart/tradingcard-chart/TradingCardChart"
    )
);

const CommonTradingCard = memo(function CommonTradingCard(
  props: React.PropsWithChildren<Props>
) {
  const [showOrders, setShowOrders] = useState(true);
  const [xContraints, setXContraints] =
    useState<[number | undefined, number | undefined]>();
  const theme = getTheme(props.activeTheme);
  const candleSize = useContext(CandleSizeContext) ?? "1D";
  const isBacktesting = useContext(IsBacktestingContext);
  const focusedOrderEntry = useContext(FocusedOrderEntryContext);
  const setFocusedOrderEntry = useContext(SetFocusedOrderEntryContext);
  const setOrderModalOpened = useContext(SetOrderModalOpenContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const [showHoldingDetails, setShowHoldingDetails] = useState(false);
  const [holdingDetailsToggle, setHoldingDetailsToggle] =
    useState<HoldingDetailsToggle>({
      tpTarget: true,
      slTarget: true,
      holdingArea: true,
    });
  const overviewOrders = useMemo(() => {
    if (!props.commonWalletReport?.report?.overview_orders) return undefined;
    if (
      !Object.keys(props.commonWalletReport?.report.overview_orders).includes(
        props.currency.currency_name
      )
    )
      return undefined;
    return props.commonWalletReport?.report?.overview_orders[
      props.currency.currency_name
    ];
  }, [
    props.commonWalletReport?.report?.overview_orders,
    props.currency.currency_name,
  ]);

  const currencySpecificOrders = useMemo(() => {
    if (!props.commonWalletReport) return undefined;
    if (!props.priceData) return undefined;
    return props.commonWalletReport.report?.orders?.cases.filter(
      (order) => order.currency_name === props.currency.currency_name
    );
  }, [props.commonWalletReport, props.priceData, props.currency.currency_name]);

  const resetDisabled = useMemo(() => {
    return xContraints?.[0] === undefined && xContraints?.[1] === undefined;
  }, [xContraints]);

  const currencyUrl = useMemo(() => {
    return getCurrencyUrl(props.currency);
  }, [props.currency]);

  const { data } = usePalette(currencyUrl);
  const paletteData = useMemo(() => {
    return data;
  }, [data]);

  const holding = useMemo(() => {
    if (isBacktesting) return undefined;
    const _holding =
      props.commonWalletReport?.report?.holdings?.holding_list.find(
        (holding) => holding.currency_name === props.currency.currency_name
      );
    if (_holding) {
      setShowHoldingDetails(true);
    }
    return _holding;
  }, [
    props.commonWalletReport?.report,
    props.currency,
    setShowHoldingDetails,
    isBacktesting,
  ]);

  const holdingChange = useMemo(() => {
    if (!holding) return undefined;
    return calcPctChange(holding.buy_price, holding.current_price);
  }, [holding]);

  return (
    <div
      ref={containerRef}
      className="common-trading-card-container"
      style={
        focusedOrderEntry?.currencyName === props.currency.currency_name
          ? { border: `2px solid ${paletteData.vibrant}` }
          : {}
      }
    >
      <div
        className="currency-theme-color"
        style={{
          backgroundColor: paletteData.vibrant,
        }}
      />
      <div
        className="currency-theme-color right"
        style={{
          backgroundColor: paletteData.darkVibrant,
        }}
      />
      <div className="common-trading-card-row header">
        <div className="common-trading-card-row-cell currency">
          <Tooltip
            label={
              <>
                <label className="dimmed-label">
                  Name:
                  <strong> {props.currency.currency_name}</strong>
                </label>
                <br />
                <label className="dimmed-label">
                  Rank:
                  <strong> {props.currency.cmc_rank}</strong>
                </label>
              </>
            }
            position="right"
            placement="start"
            withArrow
            radius={"xs"}
            transition={"fade"}
            transitionDuration={200}
          >
            <Avatar
              src={currencyUrl}
              alt={props.currency.currency_name}
              size={"sm"}
              radius="xl"
            />
          </Tooltip>
        </div>
        <div className="common-trading-icon-button-row">
          <CommonIconButton
            activeTheme={props.activeTheme}
            label={"Reset zoom"}
            style={{
              border: `1px solid ${resetDisabled ? "transparent" : paletteData.vibrant}`,
            }}
            onClick={() => setXContraints([undefined, undefined])}
            flat={resetDisabled}
            borderTheme={!resetDisabled ? "gray-accent" : undefined}
            icon={<CiZoomOut />}
            disabled={resetDisabled}
          />
          <CommonIconButton
            activeTheme={props.activeTheme}
            label={"View chandlestick chart"}
            onClick={() => props.setFocusedCurrency(props.currency)}
            icon={<MdCandlestickChart />}
          />

          {props.commonWalletReport?.report && (
            <CommonIconButton
              activeTheme={props.activeTheme}
              label={showOrders ? "Hide Orders" : "Show Orders"}
              onClick={() => setShowOrders(!showOrders)}
              flat={!showOrders}
              icon={<CgArrowsExchange />}
              disabled={
                !props.commonWalletReport ||
                props.commonWalletReport?.report?.orders?.cases.length === 0
              }
            />
          )}
          {holding && (
            <CommonIconButton
              flat={!showHoldingDetails}
              activeTheme={props.activeTheme}
              label={"Holding details"}
              onClick={() => setShowHoldingDetails(!showHoldingDetails)}
              icon={<CgDetailsMore />}
            />
          )}
        </div>
        {holding && showHoldingDetails && (
          <motion.div
            initial={{ opacity: 0, scaleX: 0.1 }}
            animate={{ opacity: 1, scaleX: 1 }}
            exit={{ opacity: 0, scaleX: 0.1 }}
            transition={{ duration: 0.4 }}
            className="current-holding-details-container"
          >
            {holding.take_profit_target_price && (
              <div
                className="current-holding-details-block"
                onClick={() => {
                  setHoldingDetailsToggle({
                    ...holdingDetailsToggle,
                    tpTarget: !holdingDetailsToggle.tpTarget,
                  });
                }}
              >
                <label className="dimmed-label">TP:</label>
                <CountUp
                  className={
                    holdingDetailsToggle.tpTarget
                      ? "wallet-stat-label-profit"
                      : "wallet-stat-label dimmed-label"
                  }
                  end={holding.take_profit_target_price}
                  start={holding.take_profit_target_price}
                  preserveValue
                  duration={1}
                  prefix="$ "
                  decimals={determineDecimals(holding.take_profit_target_price)}
                />
              </div>
            )}
            {holding.stoploss_target_price && (
              <div
                className="current-holding-details-block"
                onClick={() => {
                  setHoldingDetailsToggle({
                    ...holdingDetailsToggle,
                    slTarget: !holdingDetailsToggle.slTarget,
                  });
                }}
              >
                <label className="dimmed-label">SL:</label>
                <CountUp
                  className={
                    holdingDetailsToggle.slTarget
                      ? "wallet-stat-label-deficit"
                      : "wallet-stat-label dimmed-label"
                  }
                  end={holding.stoploss_target_price}
                  start={holding.stoploss_target_price}
                  preserveValue
                  duration={1}
                  prefix="$ "
                  decimals={determineDecimals(holding.stoploss_target_price)}
                />
              </div>
            )}
            {holdingChange !== undefined && (
              <div
                className="current-holding-details-block"
                onClick={() => {
                  setHoldingDetailsToggle({
                    ...holdingDetailsToggle,
                    holdingArea: !holdingDetailsToggle.holdingArea,
                  });
                }}
              >
                {holdingChange > 0 ? (
                  <PiChartLineUp
                    color={
                      holdingDetailsToggle.holdingArea
                        ? theme.buyOrderStrokeHover
                        : theme.text
                    }
                    size={16}
                  />
                ) : (
                  <PiChartLineDown
                    color={
                      holdingDetailsToggle.holdingArea
                        ? theme.sellOrderStrokeHover
                        : theme.text
                    }
                    size={16}
                  />
                )}
                <CountUp
                  className={
                    !holdingDetailsToggle.holdingArea
                      ? "wallet-stat-label"
                      : holdingChange === 0
                      ? "wallet-stat-label"
                      : holdingChange > 0
                      ? "wallet-stat-label-profit"
                      : "wallet-stat-label-deficit"
                  }
                  end={holdingChange}
                  preserveValue
                  start={holdingChange}
                  duration={1}
                  decimals={2}
                  suffix="%"
                />
              </div>
            )}
          </motion.div>
        )}
        <div
          className={
            "holding-indicator-badge" + (props.isHolding ? " active" : "")
          }
        >
          holding
        </div>
        {props.isHolding && (
          <div
            className="currency-theme-color holding"
            style={{
              backgroundColor: theme.buyOrderStrokeHover,
            }}
          />
        )}
      </div>
      <div className="common-trading-card-row center">
        <div className="common-trading-card-row-cell action-indicator">
          <ActionIndicator
            activeTheme={props.activeTheme}
            isHolding={props.isHolding}
            currency={props.currency}
            commonWalletReport={props.commonWalletReport}
          />
        </div>
        <div
          className="common-trading-card-row-cell chart"
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <Suspense
            fallback={
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NeotonSpinner activeTheme={props.activeTheme} />
              </div>
            }
          >
            <TradingCardChart
              activeTheme={props.activeTheme}
              priceData={props.priceData}
              filteredOrders={currencySpecificOrders}
              currencyOverviewOrders={overviewOrders}
              currentOverviewTimestamp={
                props.commonWalletReport?.report?.current_overview_timestamp
              }
              candleSize={candleSize}
              loading={props.isLoading}
              setXContraints={setXContraints}
              xContraints={xContraints}
              isHolding={props.isHolding}
              showHoldingDetails={showHoldingDetails}
              holding={holding}
              holdingDetailsToggle={holdingDetailsToggle}
              hideOrders={!showOrders}
              hideBrush
              showFirstAndLastDate
              bottomCenterChild={
                focusedOrderEntry?.currencyName ===
                  props.currency.currency_name && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                    className={"focused-order-popup"}
                  >
                    <CommonButton
                      compact
                      activeTheme={props.activeTheme}
                      label={"View details"}
                      leftIcon={<FaMagnifyingGlassDollar />}
                      style={{
                        border: `1px solid ${paletteData.vibrant}`,
                      }}
                      onClick={() => {
                        setOrderModalOpened(true);
                      }}
                    />
                    <CommonButton
                      compact
                      activeTheme={props.activeTheme}
                      label={"Clear"}
                      borderTheme="gray-accent"
                      leftIcon={<FiX />}
                      onClick={() => {
                        setFocusedOrderEntry(undefined);
                      }}
                    />
                  </motion.div>
                )
              }
            />
          </Suspense>
        </div>
        {/* <div className="common-trading-card-row-cell immediate-action-indicator">
for future feature

        </div> */}
      </div>
    </div>
  );
});

export interface HoldingDetailsToggle {
  tpTarget: boolean;
  slTarget: boolean;
  holdingArea: boolean;
}
export default CommonTradingCard;
