import { useCallback, useContext, useMemo, useState } from "react";
import { ApplicationIndicators } from "../../../../interfaces/Indicators";
import "./code-fragments-overview.scss";
import { CriteriaFragment } from "../../criteria-fragments/CriteriaFragment";
import { Badge, Slider, Tooltip } from "@mantine/core";
import { FragmentType } from "../../../../utils/ParseCriteriaUtil";
import { UserPermissionsContext } from "../../../../App";
import { AICurrenciesContext } from "../CaseControl";
import { IndicatorInfosContext } from "../../../../shared-service-contexts/IndicatorsInfoContext";
import { motion } from "framer-motion";
import { FiX } from "react-icons/fi";
import { EditedCriteriaContext } from "../NewCriteriaEditor";
import { IoIosSettings } from "react-icons/io";
import { Currency } from "../../../../interfaces/Currency";
import { SelectedCaseContext } from "../../../../pages/strategy-page/StrategyPage";
import { CurrencyContext } from "../../../../shared-service-contexts/CurrencyContext";
import { ChartNavigation } from "../../ChartNavigation";
import { getAllowedTimeInterval } from "../../../../utils/CandleCountUtil";
import { useAuth0 } from "@auth0/auth0-react";
import { StrategyManagerService } from "../../../../services/StrategyManagerService";
import { useQuery } from "react-query";
import { ThresholdChart } from "../../../chart-components/threshold-chart/ThresholdChart";
import { CommonButton } from "../../../buttons/neoton-common-button/CommonButton";
import CountUp from "react-countup";
import { PricesOverviewRequestPayload } from "../../../../pages/common/TradingDashboard";
import { getTheme } from "../../../../utils/themeUtil";
import { NeotonSpinner } from "../../../custom-loaders/PixelLoader";
interface Props {
  activeTheme: string;
  applicationIndicators: ApplicationIndicators;
  criteriaType: string;
  allowedFragmentTypes: string[];
  focusedFragmentIndex: number | undefined;
  addFragment: (fragment: string) => void;
  candleSize: string;
  tradingCurrencies: string[];
  removeCurrency: (currency: string) => void;
}
export function AICodeFragmentsOverview(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const sliderStroke =
    props.criteriaType.toUpperCase() === "ENTRY"
      ? theme.buyOrderStrokeHover
      : theme.sellOrderStrokeHover;
  const { getAccessTokenSilently } = useAuth0();
  const editedCriteria = useContext(EditedCriteriaContext);
  const currencies = useContext(CurrencyContext);
  const aiCurrencies = useContext(AICurrenciesContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const userPermissions = useContext(UserPermissionsContext);
  const selectedCase = useContext(SelectedCaseContext);
  const hasPermissions = userPermissions?.includedPermissions.includes("pro");
  const [thresholdModifierPayload, setThresholdModifierPayload] = useState<
    ThresholdModifierPayload | undefined
  >();
  const defaultRegressionMultiplier = 1.0;
  const [regressionMultiplier, setRegressionMultiplier] = useState(
    defaultRegressionMultiplier
  );
  const [exitThreshold, setExitThreshold] = useState<number | undefined>(
    undefined
  );
  const [entryThreshold, setEntryThreshold] = useState<number | undefined>(
    undefined
  );
  const [thresholdWindowHits, setThresholdWindowHits] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAiIndicatorWindow = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !thresholdModifierPayload) return;
    try {
      setLoading(true);
      const response =
        await StrategyManagerService.blueprint.getAiIndicatorWindow(
          token,
          thresholdModifierPayload.currency.currency_name,
          thresholdModifierPayload.indicatorName,
          thresholdModifierPayload.candleSize,
          thresholdModifierPayload.timeInterval[0],
          thresholdModifierPayload.timeInterval[1]
        );
      return response.data;
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, thresholdModifierPayload, setLoading]);

  const fetchPriceData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !thresholdModifierPayload) return;

    const pricesOverviewPayload: PricesOverviewRequestPayload = {
      candle_size: thresholdModifierPayload.candleSize,
      currencies: [thresholdModifierPayload.currency.currency_name],
      start: thresholdModifierPayload.timeInterval[0],
      stop: thresholdModifierPayload.timeInterval[1],
    };
    const response = await StrategyManagerService.blueprint.getPricesOverview(
      token,
      pricesOverviewPayload
    );
    return response.data;
  }, [thresholdModifierPayload, getAccessTokenSilently]);

  const pricesOverviewQuery = useQuery(
    ["priceDataThreshold", thresholdModifierPayload],
    fetchPriceData,
    {
      cacheTime: 0,
      staleTime: 60000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const aiIndicatorWindowQuery = useQuery(
    ["aiIndicatorWindow", thresholdModifierPayload],
    fetchAiIndicatorWindow,
    {
      cacheTime: 0,
      staleTime: 60000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const exitModifierMode = useCallback(() => {
    setThresholdModifierPayload(undefined);
    setRegressionMultiplier(defaultRegressionMultiplier);
  }, [
    setThresholdModifierPayload,
    setRegressionMultiplier,
    defaultRegressionMultiplier,
  ]);

  const thresholdData: ThresholdData | undefined = useMemo(() => {
    if (!aiIndicatorWindowQuery.data) return undefined;
    const indicatorName = Object.keys(aiIndicatorWindowQuery.data)[0];
    const regressionKey = `${indicatorName}_REGRESSION_VALUE`;
    const entryThresholdKey = `${indicatorName}_ENTRY_THRESHOLD`;
    const exitThresholdKey = `${indicatorName}_EXIT_THRESHOLD`;
    const firstTimestamp = Object.keys(
      aiIndicatorWindowQuery.data[indicatorName]
    )[0];
    setEntryThreshold(
      aiIndicatorWindowQuery.data[indicatorName][firstTimestamp][
        entryThresholdKey
      ]
    );
    setExitThreshold(
      aiIndicatorWindowQuery.data[indicatorName][firstTimestamp][
        exitThresholdKey
      ]
    );
    const _thresholdData: ThresholdData = {};
    for (const timestamp in aiIndicatorWindowQuery.data[indicatorName]) {
      _thresholdData[timestamp] =
        aiIndicatorWindowQuery.data[indicatorName][timestamp][regressionKey];
    }
    return _thresholdData;
  }, [aiIndicatorWindowQuery.data, setEntryThreshold, setExitThreshold]);

  const modifiable = useMemo(() => {
    if (!thresholdModifierPayload) return false;
    return (
      (exitThreshold !== 0.0 &&
        thresholdModifierPayload.criteriaType.toUpperCase() === "EXIT") ||
      (entryThreshold !== 0.0 &&
        thresholdModifierPayload.criteriaType.toUpperCase() === "ENTRY")
    );
  }, [exitThreshold, entryThreshold, thresholdModifierPayload]);

  // get currencies not supoorted by AI
  const illegalCurrencies = props.tradingCurrencies.filter(
    (currency) => !aiCurrencies.includes(currency)
  );

  const candleSizeSupportedAIIndicators = Object.keys(
    props.applicationIndicators.ai_supported_candle_sizes
  ).filter((key) =>
    props.applicationIndicators.ai_supported_candle_sizes[key].includes(
      props.candleSize
    )
  );

  const aiIndicatorFragmentsEnabled =
    hasPermissions && illegalCurrencies.length === 0;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.3 }}
      exit={{ opacity: 0 }}
      className="code-fragments-overview-container"
    >
      <>
        {!hasPermissions && (
          <label className="dimmed-label">Requires PRO subscription</label>
        )}
        {illegalCurrencies.length > 0 && (
          <div className="error-text-container">
            <label>The following currencies are not supported by AI:</label>
            <label className="dimmed-label">Click to remove</label>
            <div className="error-text">
              {illegalCurrencies.map((currencyName) => (
                <Badge
                  leftSection={<FiX />}
                  onClick={() => props.removeCurrency(currencyName)}
                  key={currencyName}
                  style={{ cursor: "pointer" }}
                  variant="outline"
                  color="red"
                  size="sm"
                  radius="xs"
                >
                  {currencyName}
                </Badge>
              ))}
            </div>
          </div>
        )}

        {candleSizeSupportedAIIndicators.length === 0 && (
          <label className="dimmed-label">
            There are currently no AI signals supported on candle size:{" "}
            {props.candleSize}
          </label>
        )}

        {indicatorsInfo && !thresholdModifierPayload && (
          <>
            {Object.keys(indicatorsInfo.ai_indicator_series).map((key, idx) => {
              const indicator_info = indicatorsInfo.ai_indicator_series[key];
              if (
                !indicator_info.candle_size_support.includes(props.candleSize)
              )
                return;
              return (
                <div className="ai-series-container" key={key + "-" + idx}>
                  <label className="series-name">
                    {indicator_info.ai_alias} Series
                  </label>
                  <div className="code-fragments-container">
                    {Object.keys(
                      props.applicationIndicators.ai_translations
                    ).map((aiAlias, index) => {
                      if (!aiAlias.includes(props.criteriaType.toUpperCase()))
                        return null;
                      const aiAliasBase = aiAlias.replace('_ENTRY', '').replace('_EXIT', '');
                      if(props.applicationIndicators.ai_indicator_blacklist?.includes(aiAliasBase)) return null;
                      const fragmentType: FragmentType = {
                        fragment: aiAlias,
                        type: "AI_VARIABLE",
                        className: "fragment-badge-variable-ai",
                      };
                      const enabled =
                        aiIndicatorFragmentsEnabled &&
                        !editedCriteria.includes(aiAlias) &&
                        props.allowedFragmentTypes.includes(fragmentType.type);
                      if (
                        !props.applicationIndicators.ai_translations[
                          aiAlias
                        ].includes(key)
                      )
                        return null;

                      return (
                        <div
                          className="ai-fragment-container"
                          key={aiAlias + "-" + index}
                        >
                          <div
                            className="code-fragment-container"
                            id={enabled ? "" : "disabled-code-fragment"}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (!enabled) return;
                              if (
                                !props.allowedFragmentTypes.includes(
                                  fragmentType.type
                                )
                              )
                                return;
                              props.addFragment(aiAlias);
                            }}
                          >
                            <CriteriaFragment
                              indicators={
                                props.applicationIndicators.indicators
                              }
                              activeTheme={props.activeTheme}
                              fragmentType={fragmentType}
                              index={index}
                              small
                            />
                          </div>

                          <Tooltip
                            label="Adjust signal aggression"
                            position="right"
                            placement="center"
                            className="code-fragment-extra-button ai"
                            id={enabled ? "" : "disabled-code-fragment"}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (!enabled) return;

                              const caseCurrencies =
                                selectedCase?.included_currencies.filter((c) =>
                                  aiCurrencies.includes(c)
                                );
                              const selectedCurrencyName =
                                caseCurrencies?.[0] ?? aiCurrencies[0];
                              const selectedCurrency = currencies?.find(
                                (c) => c.currency_name === selectedCurrencyName
                              );
                              if (!selectedCurrency) return;
                              const now = new Date().getTime();
                              const timeInterval = getAllowedTimeInterval(
                                now,
                                1200,
                                props.candleSize,
                                now
                              );

                              setThresholdModifierPayload({
                                indicatorName: aiAlias
                                  .replace("_ENTRY", "")
                                  .replace("_EXIT", ""),
                                criteriaType: props.criteriaType,
                                candleSize: props.candleSize,
                                currency: selectedCurrency,
                                timeInterval: [timeInterval[0], now],
                              });
                            }}
                          >
                            <IoIosSettings />
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}

        {thresholdModifierPayload && currencies && (
          <>
            <div className="threshold-modifier-upper-row">
              <CommonButton
                onClick={exitModifierMode}
                activeTheme={props.activeTheme}
                label="close"
                compact
                leftIcon={<FiX />}
                style={{ maxHeight: "30px" }}
              />
              <ChartNavigation
                activeTheme={props.activeTheme}
                candleSize={thresholdModifierPayload.candleSize}
                currentInterval={thresholdModifierPayload.timeInterval}
                handleNavigationChartClick={(candleSize, centerTimestamp) => {
                  const newInterval = getAllowedTimeInterval(
                    centerTimestamp ?? new Date().getTime(),
                    1200,
                    candleSize,
                    centerTimestamp ?? new Date().getTime()
                  );
                  setThresholdModifierPayload({
                    ...thresholdModifierPayload,
                    candleSize,
                    timeInterval: [newInterval[0], newInterval[1]],
                  });
                }}
                selectedCurrency={thresholdModifierPayload.currency}
                currencySelectionOverride={currencies.filter((c) =>
                  aiCurrencies.includes(c.currency_name)
                )}
                onCurrencySelection={(currency) => {
                  setThresholdModifierPayload({
                    ...thresholdModifierPayload,
                    currency: currency,
                  });
                }}
              />
            </div>
            {loading ? (
              <div className="threshold-loading-container">
                <NeotonSpinner activeTheme={props.activeTheme} />
              </div>
            ) : (
              <>
                {modifiable && (
                  <div className="threshold-modifier-header">
                    <label className="dimmed-label">
                      Aggression multiplier: {regressionMultiplier}
                    </label>
                    <label
                      className="dimmed-label"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {props.criteriaType} zones:
                      <CountUp
                        end={thresholdWindowHits}
                        preserveValue
                        start={0}
                        duration={1}
                        decimals={0}
                        prefix=" "
                      />
                    </label>
                  </div>
                )}

                {thresholdData &&
                  thresholdModifierPayload &&
                  entryThreshold !== undefined &&
                  exitThreshold !== undefined && (
                    <div className="threshold-modifier-control-container">
                      {modifiable ? (
                        <>
                          <Slider
                            max={9.99}
                            min={0.01}
                            step={0.01}
                            defaultValue={defaultRegressionMultiplier}
                            value={regressionMultiplier}
                            onChange={(value) =>
                              setRegressionMultiplier(
                                parseFloat(value.toPrecision(2))
                              )
                            }
                            showLabelOnHover={false}
                            styles={{
                              thumb: {
                                height: 16,
                                width: 10,
                                borderWidth: 1,
                                borderRadius: 2,
                                borderColor: sliderStroke,
                                filter: "grayScale(0) !important",
                                backgroundColor: sliderStroke,
                              },

                              bar: {
                                backgroundColor: "transparent",
                              },
                              track: {
                                backgroundColor: theme.cardInterior,
                                borderRadius: "25%",
                                border: "1px solid " + sliderStroke,
                                filter: "grayScale(0.6)",
                              },
                            }}
                          />
                          <ThresholdChart
                            activeTheme={props.activeTheme}
                            thresholdData={thresholdData}
                            thresholdModifierPayload={thresholdModifierPayload}
                            priceData={
                              pricesOverviewQuery.data?.currencyPrices[0]
                            }
                            entryThreshold={entryThreshold}
                            exitThreshold={exitThreshold}
                            regressionMultiplier={regressionMultiplier}
                            setThresholdWindowHits={setThresholdWindowHits}
                          />
                        </>
                      ) : (
                        <label className="dimmed-label">
                          You cannot adjust this signal
                        </label>
                      )}
                    </div>
                  )}
              </>
            )}
          </>
        )}

        <>
          {thresholdModifierPayload && regressionMultiplier !== 1.0 ? (
            <div
              className="code-fragments-container"
              onClick={(e: any) => {
                e.stopPropagation();
                if (!modifiable) return;
                if (
                  !props.allowedFragmentTypes.includes("AI_VARIABLE") ||
                  !thresholdModifierPayload
                )
                  return;
                props.addFragment(
                  `${
                    thresholdModifierPayload.indicatorName
                  }_${thresholdModifierPayload.criteriaType.toUpperCase()}[RM:${regressionMultiplier}]`
                );
                exitModifierMode();
              }}
            >
              <CriteriaFragment
                indicators={props.applicationIndicators.indicators}
                activeTheme={props.activeTheme}
                fragmentType={{
                  fragment: `${
                    thresholdModifierPayload.indicatorName
                  }_${thresholdModifierPayload.criteriaType.toUpperCase()}[RM:${regressionMultiplier}]`,
                  type: "AI_VARIABLE",
                  className: "fragment-badge-variable-ai",
                  modifierRegexPattern: "[RM:",
                  modifierReplacement: " [ * ",
                }}
                small
              />
            </div>
          ) : (
            <>
              {thresholdModifierPayload && (
                <div
                  className="code-fragments-container"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (!modifiable) return;
                    if (
                      !props.allowedFragmentTypes.includes("AI_VARIABLE") ||
                      !thresholdModifierPayload
                    )
                      return;
                    props.addFragment(
                      `${
                        thresholdModifierPayload.indicatorName
                      }_${thresholdModifierPayload.criteriaType.toUpperCase()}[RM:${regressionMultiplier}]`
                    );
                    exitModifierMode();
                  }}
                >
                  <CriteriaFragment
                    indicators={props.applicationIndicators.indicators}
                    activeTheme={props.activeTheme}
                    fragmentType={{
                      fragment: `${
                        thresholdModifierPayload.indicatorName
                      }_${thresholdModifierPayload.criteriaType.toUpperCase()}`,
                      type: "AI_VARIABLE",
                      className: "fragment-badge-variable-ai",
                    }}
                    small
                  />
                </div>
              )}
            </>
          )}
        </>
      </>
    </motion.div>
  );
}

export interface ThresholdModifierPayload {
  indicatorName: string;
  criteriaType: string;
  candleSize: string;
  currency: Currency;
  timeInterval: [number, number];
}

export interface ThresholdData {
  [timestamp: string]: number;
}
