import "./strategy-popup.scss";
import { Popover } from "@mantine/core";
import {
  Case,
  StrategyVersion,
} from "../../../interfaces/strategyInterfaces/Strategy";
import { CasePopupHeaderControl } from "../../strategy-body/case-control/CasePopupControl";
import { CaseControl } from "../../strategy-body/case-control/CaseControl";
import { Currency } from "../../../interfaces/Currency";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { RiDropdownList } from "react-icons/ri";
import { RiCodeSSlashLine, RiCodeView } from "react-icons/ri";
import { StrategyVersionIcon } from "../identicon/StrategyVersionIcon";
import { CaseSelection } from "../../strategy-body/CaseSelection";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  opened: boolean;
  popupMode: "list" | "edit" | "view";
  newCaseClick?: () => void;
  mainClick?: () => void;
  listCasesClick?: () => void;
  onClose?: () => void;
  handleDelete?: () => void;
  handleClose?: () => void;
  addCase?: (caseName: string) => void;
  deleteCase?: (caseName: string) => void;
  goToCase: () => void;
  selectCase: (_selectedCase: Case | undefined) => void;
  selectedCase?: Case;
  availableCurrencies?: Currency[];
  hideNewCaseButton?: boolean;
  readonly?: boolean;
  compact?: boolean;
  showExtraControls?: boolean;
  externalChild?: React.ReactNode;
  hideStrategyName?: boolean;
  hideStrategyVersionIcon?: boolean;
  versionPopoverChild?: React.ReactNode;
}
export function StrategyBodyPopup(props: React.PropsWithChildren<Props>) {
  return (
    <Popover
      className="case-popup-container"
      classNames={{
        popover: "case-popup-container",
        body: "case-popup-body",
        wrapper: "case-popup-wrapper",
        inner: "case-popup-inner",
        root: "case-popup-root" + (!props.readonly ? " editor" : ""),
      }}
      opened={props.opened}
      position="bottom"
      placement="start"
      shadow="xl"
      transition={"scale-y"}
      target={
        <div className="strategy-popup-control-container">
          <div
            className={
              "case-control-flip-button" + (props.compact ? " compact" : "")
            }
          >
            {!props.hideStrategyVersionIcon && (
              <>
                {props.versionPopoverChild ?? (
                  <StrategyVersionIcon
                    activeTheme={props.activeTheme}
                    included_currencies={props.strategy.header.currencies}
                    name={props.strategy.name}
                    strategy_id={props.strategy.strategy_id}
                    version_id={props.strategy.version_id}
                    size={30}
                  />
                )}
              </>
            )}
            <div className="case-button-column">
              {props.selectedCase && (
                <CommonButton
                  activeTheme={props.activeTheme}
                  onClick={props.listCasesClick}
                  label={`Cases (${props.strategy.body.cases.length})`}
                  leftIcon={<RiDropdownList size={20} />}
                  flat={props.readonly}
                  compact
                />
              )}
              <CommonButton
                compact={props.selectedCase ? true : false}
                activeTheme={props.activeTheme}
                onClick={props.mainClick}
                label={
                  props.selectedCase ? props.selectedCase.name : "Add Case"
                }
                leftIcon={
                  props.opened ? (
                    <RiCodeView size={20} />
                  ) : (
                    <>
                      {props.selectedCase ? (
                        <RiCodeSSlashLine size={20} />
                      ) : (
                        <RiDropdownList size={20} />
                      )}
                    </>
                  )
                }
                borderTheme={
                  props.readonly
                    ? props.opened
                      ? "gray-accent"
                      : undefined
                    : props.selectedCase
                    ? "gray-accent"
                    : "green-accent"
                }
              />
            </div>

            {props.externalChild && props.externalChild}
          </div>
        </div>
      }
      zIndex={98}
      onClose={props.onClose}
    >
      <CasePopupHeaderControl
        readonly={props.readonly}
        handleClose={props.handleClose}
        listCasesClick={
          ["edit", "view"].includes(props.popupMode)
            ? props.listCasesClick
            : undefined
        }
      />
      {props.selectedCase && props.popupMode === "edit" && (
        <CaseControl
          activeTheme={props.activeTheme}
          case={props.selectedCase}
          currencies={props.availableCurrencies}
        />
      )}
      {props.selectedCase && props.popupMode === "view" && (
        <CaseControl
          readonly
          activeTheme={props.activeTheme}
          case={props.selectedCase}
          currencies={props.availableCurrencies}
        />
      )}

      {props.popupMode === "list" && props.availableCurrencies && (
        <CaseSelection
          readonly={props.readonly}
          activeTheme={props.activeTheme}
          autoFocusNewCase={props.strategy.body.cases.length === 0}
          strategy={props.strategy}
          openCase={props.goToCase}
          addCase={props.addCase}
          selectCase={props.selectCase}
        />
      )}
    </Popover>
  );
}
