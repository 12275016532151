import { motion } from "framer-motion";
import { forwardRef, useContext } from "react";
import { ApplicationSettingsContext } from "../../../App";

const NeotonCard = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const applicationSettings = useContext(ApplicationSettingsContext);
  return (
    <motion.div
      ref={ref}
      onClick={props.onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      id={props.id}
      className={
        "card" +
        (props.className ? " " + props.className : "") +
        (applicationSettings?.particlesSettings.enabled ? " transparent" : "")
      }
    >
      {props.children}
    </motion.div>
  );
});

interface Props {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  onClick?: () => void;
  transparent?: boolean;
}

export default NeotonCard;
