import { ceil, log10 } from "mathjs";
import { BacktestBillingDuration } from "../components/common/common-user-stats/CommonUserStats";

export function getPriceDecimalCount(prices: number[] | undefined) {
  if (prices) {
    let average = prices.reduce((a, b) => a + b, 0) / prices.length;
    return determineDecimals(average);
  }
  return 0;
}

export const determineDecimals = (value: number | null | undefined) => {
  if (value === null || value === undefined) return 0;
  if (value === 0) return 0;
  const append = value > 1000 ? 0 : 2;
  return Math.max(0, ceil(-log10(value))) + append;
};

export const determinePctDecimals = (value: number) => {
  if (value > 1000) return 0;
  return 2;
};

export function formatValue(value: number) {
  if (value < 0.1) {
    return Number(value).toFixed(5);
  }
  if (value < 1) {
    return Number(value).toFixed(3);
  }
  return `${Math.round(value)}`;
}

export const roundToTwo = (value: number) => {
  return Math.round(value * 100) / 100;
};

export function toCompactTimeString(timestamp: number | undefined) {
  if (!timestamp) return "0";
  // return in format 12/31/2020
  const dateObj = new Date(timestamp);
  const date = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();
  const timeString = `${month}/${date}/${year}`;
  return timeString;
}

export function toUTCTimestring(
  timestamp: number | undefined,
  candleSize: string = "1h"
) {
  if (!timestamp) return "0";
  const dateObj = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = dateObj.getUTCDate();
  const month = months[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();
  const dayString = `${date} ${month} ${year}`;
  if (candleSize === "1D") {
    return dayString;
  }
  const hours = (dateObj.getUTCHours() < 10 ? "0" : "") + dateObj.getUTCHours();
  const minutes =
    (dateObj.getUTCMinutes() < 10 ? "0" : "") + dateObj.getUTCMinutes();
  return `${dayString} ${hours}:${minutes}`;
}

export function adjustTimestampToReference(
  timestamp: number,
  reference: "1D" | "1h" | "5m"
) {
  const date = new Date(timestamp);

  if (reference === "1D") {
    // Set to 12:00 AM GMT
    date.setUTCHours(0, 0, 0, 0);
  } else if (reference === "1h") {
    // Round back to the latest hour GMT
    date.setUTCMinutes(0, 0, 0);
  } else if (reference === "5m") {
    // Round back to the last 5 minutes
    const minutes = date.getUTCMinutes();
    date.setUTCMinutes(minutes - (minutes % 5), 0, 0);
  } else {
    // If the reference is not recognized, return the original timestamp
    return timestamp;
  }

  return date.getTime();
}

export function getPapertraderSeasonStartPadding() {
  return 60 * 60 * 24 * 3 * 1000; // 3 days
}

export function convertSecondsToFormattedTime(seconds: number) {
  // return seconds to days, hours, minutes, seconds format
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: remainingSeconds,
  } as BacktestBillingDuration;
}

export function toTimestring(
  timestamp: number | undefined,
  candleSize: string = "1h"
) {
  if (!timestamp) return "0";
  const dateObj = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  const dayString = `${date} ${month} ${year}`;
  if (candleSize === "1D") {
    return dayString;
  }
  const hours = (dateObj.getHours() < 10 ? "0" : "") + dateObj.getHours();
  const minutes = (dateObj.getMinutes() < 10 ? "0" : "") + dateObj.getMinutes();
  return `${dayString} ${hours}:${minutes}`;
}

export function toLocalTimeString(
  timestamp: number | undefined,
  candleSize: string
) {
  if (!timestamp) return "0";
  const dateObj = new Date(timestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  const dayString = `${date} ${month} ${year}`;
  if (candleSize === "1D") {
    return dayString;
  }
  const hours = (dateObj.getHours() < 10 ? "0" : "") + dateObj.getHours();
  const minutes = (dateObj.getMinutes() < 10 ? "0" : "") + dateObj.getMinutes();
  return `${dayString} ${hours}:${minutes}`;
}

export function toIsoTimeString(timestamp: number) {
  const date = new Date(timestamp * 1000);
  return date.toISOString();
}

export function convertToReadableDateString(timestamp: number) {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString();
}
export function convertToReadableDateStringFromUnixInMs(timestamp: number) {
  const date = new Date(timestamp);
  return date.toLocaleDateString();
}

// convert unix timestamp to readable date string
export function convertToReadableDateTimeString(
  timestamp: number,
  excludeSeconds?: boolean
) {
  const date = new Date(timestamp * 1000);
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const hourString = hour < 10 ? `0${hour}` : `${hour}`;
  const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
  const secondString = second < 10 ? `0${second}` : `${second}`;
  return excludeSeconds
    ? `${date.toLocaleDateString()} ${hourString}:${minuteString}`
    : `${date.toLocaleDateString()} ${hourString}:${minuteString}:${secondString}`;
}

export const roundNumberToTwoDecimals = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const getStatusText = (_statusCode?: number) => {
  if (_statusCode === undefined) return "";
  switch (_statusCode) {
    case 0:
      return "Staging";
    case 1:
      return "Pending";
    case 2:
      return "Preparing";
    case 3:
      return "Queued";
    case 4:
      return "Running";
    case 5:
      return "Finishing";
    case 6:
      return "Done";
    case 7:
      return "Failed";
    case 8:
      return "Cancelled";
    default:
      return "Unknown";
  }
};

export const formatPriceToDollar = (price: number) => {
  // format the price to a us dollar format
  // with the correct delimiter and decimal seperator
  return price.toFixed(2);
};

export const formatToUsd = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const getPreviewName = (name: string, length?: number) => {
  length = length ?? 10;
  // maxiumum length of 10 characters followed by ...
  if (name.length > length) {
    return name.substring(0, length) + "...";
  }
  return name;
};

export const calcPctChange = (originalValue: number, newValue: number) => {
  if (originalValue === 0) return 0; // Avoid division by zero
  return ((newValue - originalValue) / originalValue) * 100;
};

export const calcPctOfTotal = (part: number, whole: number): number => {
  return (part / whole) * 100;
};
