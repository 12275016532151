import "./common-market-cap-pool-header.scss";
import { PoolFunds } from "../../../interfaces/backtester/WalletFunds";
import { Holdings } from "../../../interfaces/backtester/BacktestTradingReport";
import { Currency } from "../../../interfaces/Currency";
import { MarketCapPoolFunds } from "./CommonMarketCapPoolFundsContainer";
import { useState, useEffect, useMemo, useContext } from "react";
import { Tooltip } from "@mantine/core";
import { motion } from "framer-motion";
import { GoChevronDown } from "react-icons/go";
import CountUp from "react-countup";
import { BaseCurrency } from "../../../interfaces/common-trading/TradingAccount";
import { BaseCurrencyType } from "../../../utils/cdnUtil";
import { IsBacktestingContext } from "../../../pages/backtester-page/BacktesterPage";

interface Props {
  activeTheme: string;
  label: string;
  poolFunds: PoolFunds | undefined;
  holdings: Holdings | undefined;
  poolCurrencies: Currency[] | undefined;
  poolCompositionType: string;
  dynamicPoolExplanation: React.ReactNode | undefined;
  collapsed?: boolean;
  toggleCollapse?: () => void;
  pctAllocation?: number;
  baseCurrency: BaseCurrencyType | undefined;
}
export function CommonMarketCapPoolHeader(
  props: React.PropsWithChildren<Props>
) {
  const [showMarketCapPoolFunds, setShowMarketCapPoolFunds] = useState(false);
  const [showPoolAllocation, setShowPoolAllocation] = useState(false);
  const isBacktesting = useContext(IsBacktestingContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMarketCapPoolFunds(true);
    }, 2000); // Delay of 2000 milliseconds
    const timer2 = setTimeout(() => {
      setShowPoolAllocation(props.pctAllocation !== 0);
    }, 6000);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    }; // Cleanup to avoid memory leaks
  }, []); // Empty dependency array to run only once on mount

  const poolValue = useMemo(() => {
    const _currencyNames = props.poolCurrencies?.map(
      (currency) => currency.currency_name
    );
    const _poolHoldings = props.holdings?.holding_list?.filter((holding) =>
      _currencyNames?.includes(holding.currency_name)
    );
    if (_poolHoldings?.length === 0) return undefined;
    let value = props.poolFunds?.available_amount ?? 0;
    if (_poolHoldings) {
      value += _poolHoldings.reduce(
        (acc, holding) => acc + holding.current_value,
        0
      );
    }
    return value;
  }, [props.poolCurrencies, props.holdings, props.poolFunds]);

  return (
    <div className="common-market-cap-pool-header-container">
      <div className={"pool-type-indicator " + props.poolCompositionType} />

      <Tooltip
        wrapLines
        label={
          <div className="market-cap-pool-header-tooltip-container">
            <label>{props.label}</label>
            {props.dynamicPoolExplanation}
          </div>
        }
        position="right"
        withArrow
      >
        <motion.label
          className="market-cap-pool-header-label"
          initial={{ opacity: 0, scale: 0 }}
          animate={{
            opacity: showPoolAllocation ? 1 : 0,
            minWidth: 100,
            scale: showPoolAllocation ? 1 : 0,
          }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          <CountUp
            className={"wallet-stat-label dimmed-label"}
            start={0}
            end={props.pctAllocation ?? 0}
            duration={1}
            suffix="%"
            decimals={0}
          />
        </motion.label>
      </Tooltip>
      {!isBacktesting && (
        <>
          {props.holdings && showMarketCapPoolFunds && (
            <MarketCapPoolFunds
              activeTheme={props.activeTheme}
              holdings={props.holdings}
              label={props.label}
              poolCurrencies={props.poolCurrencies}
              poolFunds={props.poolFunds}
              baseCurrency={props.baseCurrency ?? "USD"}
            />
          )}
          {showMarketCapPoolFunds && poolValue ? (
            <CountUp
              className={"wallet-stat-label dimmed-label"}
              start={0}
              preserveValue
              end={poolValue}
              duration={1}
              suffix="$"
              prefix="= "
              decimals={0}
            />
          ) : undefined}
          <motion.div
            onClick={props.toggleCollapse}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, rotate: !props.collapsed ? 0 : 90 }}
            exit={{ opacity: 0 }}
          >
            <GoChevronDown />
          </motion.div>
        </>
      )}
    </div>
  );
}
