import "./common-event-log.scss";
import {
  NewPoolCompositionEvent,
  TradingEvent,
} from "../../../interfaces/common-trading/CommonTradingReport";
import { useCallback, useContext, useMemo, useRef } from "react";
import { getTheme } from "../../../utils/themeUtil";
import { MdCurrencyExchange, MdOutlineDynamicFeed } from "react-icons/md";
import { toTimestring } from "../../../utils/FormattingUtils";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { CurrencyLogoArray } from "../common-currencies/CurrencyLogoArray";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";

interface Props {
  activeTheme: string;
  events: TradingEvent[] | undefined;
  detached?: boolean;

}

interface RowItemData {
  events: TradingEvent[];
  theme: ReturnType<typeof getTheme>;
  getEventIcon: (eventLabel: string) => JSX.Element | undefined;
  getEventLabel: (eventLabel: string) => string | undefined;
  renderPoolCompositionEvent: (
    poolCompositionEvent: NewPoolCompositionEvent
  ) => JSX.Element | null;
}

export function CommonEventLog(props: React.PropsWithChildren<Props>) {
  const currencies = useContext(CurrencyContext);
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  const getEventIcon = useCallback(
    (eventLabel: string) => {
      switch (eventLabel) {
        case "pool_composition_initialized":
          return <MdCurrencyExchange color={theme.buyOrderStroke} />;
        case "pool_composition_changed":
          return <MdOutlineDynamicFeed color={theme.fragmentPattern} />;
        default:
          return undefined;
      }
    },
    [theme]
  );

  const getEventLabel = useCallback((eventLabel: string) => {
    switch (eventLabel) {
      case "pool_composition_initialized":
        return "Pool composition initialized";
      case "pool_composition_changed":
        return "Pool composition changed";
      default:
        return undefined;
    }
  }, []);

  const renderPoolCompositionEvent = useCallback(
    (poolCompositionEvent: NewPoolCompositionEvent) => {
      if (!currencies) return null;
      const highPoolCurrencies = currencies.filter((currency) =>
        poolCompositionEvent.high.includes(currency.currency_name)
      );
      const mediumPoolCurrencies = currencies.filter((currency) =>
        poolCompositionEvent.medium.includes(currency.currency_name)
      );
      const lowPoolCurrencies = currencies.filter((currency) =>
        poolCompositionEvent.low.includes(currency.currency_name)
      );
      return (
        <div className="pool-composition-event">
          <div className="pool-event-container">
            <div className="pool-event-header">
              <label className="dimmed-label">High pool</label>
            </div>
            <div className="pool-event-body">
              <CurrencyLogoArray
                currencies={highPoolCurrencies}
                size="xs"
                expandable
                previewAmount={3}
              />
            </div>
          </div>
          <div className="pool-event-container">
            <div className="pool-event-header">
              <label className="dimmed-label">Medium pool</label>
            </div>
            <div className="pool-event-body">
              <CurrencyLogoArray
                currencies={mediumPoolCurrencies}
                size="xs"
                expandable
                previewAmount={3}
              />
            </div>
          </div>
          <div className="pool-event-container">
            <div className="pool-event-header">
              <label className="dimmed-label">Low pool</label>
            </div>
            <div className="pool-event-body">
              <CurrencyLogoArray
                currencies={lowPoolCurrencies}
                size="xs"
                expandable
                previewAmount={3}
              />
            </div>
          </div>
        </div>
      );
    },
    [currencies]
  );

  const listRef = useRef<FixedSizeList>(null);

  const RowItem = useCallback(
    ({ index, style, data }: ListChildComponentProps<RowItemData>) => {
      const tradingEvent = data.events[index];
      if (!tradingEvent) return null;

      return (
        <div style={{...style, maxWidth: "98%"}} className="common-trading-event-container">
          <div className="common-trading-event-header-container">
            {data.getEventIcon(tradingEvent.event)}
            <label>{data.getEventLabel(tradingEvent.event)}</label>
            {tradingEvent.timestamp && (
              <label
                className="dimmed-label"
                style={{ marginLeft: "auto", marginRight: "5px" }}
              >
                {toTimestring(tradingEvent.timestamp)}
              </label>
            )}
          </div>
          {tradingEvent.event.includes("pool_composition") &&
            tradingEvent.new_pool_composition && (
              <>
                {data.renderPoolCompositionEvent(
                  tradingEvent.new_pool_composition
                )}
              </>
            )}
        </div>
      );
    },
    []
  );

  if (!props.events || props.events.length === 0) {
    return (
      <div className={"common-event-log" + (props.detached ? " detached" : "")}>
        <label className="dimmed-label">No events available</label>
      </div>
    );
  }

  return (
    <div className={"common-event-log" + (props.detached ? " detached" : "")}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            ref={listRef}
            height={height}
            width={width}
            className="common-event-log-list"
            itemCount={props.events?.length}
            itemSize={100} // Adjust this according to the height of your event items
            itemData={{
              events: props.events,
              theme,
              getEventIcon,
              getEventLabel,
              renderPoolCompositionEvent,
            }}
            overscanCount={5}
          >
            {RowItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
}
