import { useCallback, useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { NeotonLoader } from "../../custom-loaders/NeotonLoader";
import { StrategyVersion } from "../../../interfaces/strategyInterfaces/Strategy";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { CommonStrategySelectBody } from "../common-strategy-select/CommonStrategySelectBody";

interface Props {
  activeTheme: string;
  traderType: TraderType;
  toggleBottom: () => void;
  attachStrategy: (strategyId: string, versionId: string) => void;
}
export function CompactStrategySelectPopup(
  props: React.PropsWithChildren<Props>
) {

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [selectedStrategyVersion, setSelectedStrategyVersion] =
    useState<string>();

  const fetchUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      // delay loading to prevent flickering
      setLoading(true);
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getUserStrategies(token);
      return response.data;
    } finally {
      setLoading(false);
    }
  }, [setLoading, getAccessTokenSilently, props.traderType]);
  const fetchLiveUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      // delay loading to prevent flickering
      setLoading(true);
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getLiveStrategies(token);
      return response.data;
    } finally {
      setLoading(false);
    }
  }, [setLoading, getAccessTokenSilently, props.traderType]);

  const strategiesQuery = useQuery(["Strategies"], fetchUserStrategies, {
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 60000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const liveStrategiesQuery = useQuery(
    ["LiveStrategies"],
    fetchLiveUserStrategies,
    {
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 60000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div className="compact-strategies-popup-container">
      {loading && !strategiesQuery.data && (
        <div className="compact-loader-container">
          <NeotonLoader />
        </div>
      )}
      {!loading && strategiesQuery.data && (
        <div className="compact-strategies-popup-body">
          <CommonStrategySelectBody
            toggleBottom={props.toggleBottom}
            activeTheme={props.activeTheme}
            traderType={props.traderType}
            strategiesQuery={strategiesQuery}
            liveStrategiesQuery={liveStrategiesQuery}
            selectedStrategyVersion={selectedStrategyVersion}
            setSelectedStrategyVersion={setSelectedStrategyVersion}
            attachStrategy={props.attachStrategy}
            compact
            disableButtons
          />
        </div>
      )}
    </div>
  );
}
