import { MantineSize, Popover, RangeSlider, Tooltip } from "@mantine/core";
import { Currency } from "../../interfaces/Currency";
import {
  FixedPoolComposition,
  StrategyVersion,
} from "../../interfaces/strategyInterfaces/Strategy";
import { motion } from "framer-motion";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UpdateStrategyHeaderContext } from "../../pages/strategy-page/StrategyPage";
import { getTheme } from "../../utils/themeUtil";
import {
  useForceUpdate,
  useMediaQuery,
  useDebouncedValue,
} from "@mantine/hooks";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CurrencyLogoArray } from "../common/common-currencies/CurrencyLogoArray";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { MdOutlineDynamicFeed, MdDynamicForm } from "react-icons/md";
import {
  SelectedCurrencyContext,
  SetSelectedCurrencyContext,
} from "../shared-context/HandleCurrencyContext";
import { FixedCurrencyArrayItem } from "./FixedCurrencyArrayItem";
import { FiMinus, FiPlus } from "react-icons/fi";
import CountUp from "react-countup";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  tradingCurrencies: Currency[];
}

export function PublicHeaderEditor(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const updateHeader = useContext(UpdateStrategyHeaderContext);
  const smallScreen = useMediaQuery("(max-width: 1562px)");
  const tooltipIconSize = 18;
  const forceUpdate = useForceUpdate();

  const selectedCurrency = useContext(SelectedCurrencyContext);
  const setSelectedCurrency = useContext(SetSelectedCurrencyContext);

  const poolCompositionMode = useMemo(() => {
    return props.strategy.header.pool_composition_type || "dynamic";
  }, [props.strategy.header?.pool_composition_type]);

  const fundsDistribtutionTooltipDescription = (
    <label>
      Determines the allocation of funds in the wallet across High, Medium, and
      Low market cap pools.
    </label>
  );

  const rankDistributionTooltipDescription = (
    <>
      <label>
        Defines the allocation of cryptocurrencies within market cap pools,
        <br /> based on their rank as per Coinmarketcap's ranking system.
        <br /> Two adjustable sliders set the rank boundaries for each pool,
        allowing custom asset allocation.
      </label>
      <br />
      <label>
        <strong>MC rank</strong> or <strong>Market Capitalization rank</strong>,
        is a metric used to determine the size of a cryptocurrency in comparison
        to others by its market capitalization. Market capitalization is
        calculated by multiplying the current price of a currency by its
        circulating supply. A lower MC Rank indicates a larger market
        capitalization, suggesting that the currency is more valuable and has a
        bigger share of the market. This ranking helps investors understand a
        currency's relative size and significance in the market, guiding
        investment decisions.
      </label>
    </>
  );

  const sampleAmount = 1000; // 1000 dollars

  type CurrencyPool = "high" | "medium" | "low";

  const handleFundsDistributionChange = useCallback(
    (pool: CurrencyPool, change: number) => {
      const {
        high: oldHigh,
        medium: oldMedium,
        low: oldLow,
      } = props.strategy.header.mc_distribution;

      let newHigh = oldHigh;
      let newMedium = oldMedium;
      let newLow = oldLow;

      const isOdd = change % 2 !== 0;
      const halfDown = Math.floor(change / 2);
      const halfUp = Math.ceil(change / 2);

      function distributeSubtraction(
        poolAValue: number,
        poolBValue: number
      ): [number, number] {
        // For odd changes, give the larger subtraction to the currently larger pool
        if (isOdd) {
          if (poolAValue >= poolBValue) {
            return [poolAValue - halfUp, poolBValue - halfDown];
          } else {
            return [poolAValue - halfDown, poolBValue - halfUp];
          }
        } else {
          // Even change: just subtract halfDown from both
          return [poolAValue - halfDown, poolBValue - halfDown];
        }
      }

      if (pool === "high") {
        // Increase high
        newHigh = oldHigh + change;
        // Distribute subtraction between medium & low
        [newMedium, newLow] = distributeSubtraction(oldMedium, oldLow);
      } else if (pool === "medium") {
        newMedium = oldMedium + change;
        [newHigh, newLow] = distributeSubtraction(oldHigh, oldLow);
      } else if (pool === "low") {
        newLow = oldLow + change;
        [newHigh, newMedium] = distributeSubtraction(oldHigh, oldMedium);
      }

      // Validate all new values
      if (
        newHigh < 0 ||
        newHigh > 100 ||
        newMedium < 0 ||
        newMedium > 100 ||
        newLow < 0 ||
        newLow > 100
      ) {
        // If any value goes out of range, abort
        return;
      }

      // Also ensure total is still 100%
      if (newHigh + newMedium + newLow !== 100) {
        return;
      }

      // If all good, update
      updateHeader({
        ...props.strategy.header,
        mc_distribution: {
          high: newHigh,
          medium: newMedium,
          low: newLow,
        },
      });
    },
    [props.strategy.header, updateHeader]
  );

  const renderFundsDistributionControls = useCallback(
    (pool: CurrencyPool) => {
      return (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleFundsDistributionChange(pool, 5)}
          >
            <FiPlus />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleFundsDistributionChange(pool, -5)}
          >
            <FiMinus />
          </motion.div>
        </>
      );
    },
    [handleFundsDistributionChange]
  );

  // Dynamic pools
  const dynamicCurrencyPoolHigh = useMemo(
    () =>
      props.tradingCurrencies.filter(
        (currency) =>
          currency.cmc_rank <= props.strategy.header.rank_distribution.high
      ),
    [props.tradingCurrencies, props.strategy.header.rank_distribution.high]
  );

  const dynamicCurrencyPoolMedium = useMemo(
    () =>
      props.tradingCurrencies.filter(
        (currency) =>
          currency.cmc_rank > props.strategy.header.rank_distribution.high &&
          currency.cmc_rank <= props.strategy.header.rank_distribution.medium
      ),
    [
      props.tradingCurrencies,
      props.strategy.header.rank_distribution.high,
      props.strategy.header.rank_distribution.medium,
    ]
  );

  const dynamicCurrencyPoolLow = useMemo(
    () =>
      props.tradingCurrencies.filter(
        (currency) =>
          currency.cmc_rank > props.strategy.header.rank_distribution.medium
      ),
    [props.tradingCurrencies, props.strategy.header.rank_distribution.medium]
  );

  // Fixed pools
  const fixedCurrencyPoolHigh =
    !props.strategy.header.fixed_pool_composition || !props.strategy
      ? []
      : props.tradingCurrencies.filter((currency) =>
          props.strategy.header.fixed_pool_composition?.high.includes(
            currency.currency_name
          )
        );

  const fixedCurrencyPoolMedium =
    !props.strategy.header.fixed_pool_composition || !props.strategy
      ? []
      : props.tradingCurrencies.filter((currency) =>
          props.strategy.header.fixed_pool_composition?.medium.includes(
            currency.currency_name
          )
        );

  const fixedCurrencyPoolLow =
    !props.strategy.header.fixed_pool_composition || !props.strategy
      ? []
      : props.tradingCurrencies.filter((currency) =>
          props.strategy.header.fixed_pool_composition?.low.includes(
            currency.currency_name
          )
        );

  const moveCurrencyToPool = useCallback(
    (currency: Currency, from_pool: string, to_pool: string) => {
      const _fixedPoolComposition =
        props.strategy.header.fixed_pool_composition;
      if (!_fixedPoolComposition) return;
      const _from_pool = _fixedPoolComposition[from_pool];
      const _to_pool = _fixedPoolComposition[to_pool];
      if (!_from_pool || !_to_pool) return;
      const _currencyIndex = _from_pool.indexOf(currency.currency_name);
      if (_currencyIndex === -1) return;
      _from_pool.splice(_currencyIndex, 1);
      _to_pool.push(currency.currency_name);
      _fixedPoolComposition[from_pool] = _from_pool;
      _fixedPoolComposition[to_pool] = _to_pool;

      updateHeader({
        ...props.strategy.header,
        fixed_pool_composition: _fixedPoolComposition,
      });
      forceUpdate();
    },
    [props.strategy.header, updateHeader, forceUpdate]
  );

  const highActivePoolLength =
    poolCompositionMode === "fixed"
      ? fixedCurrencyPoolHigh.length
      : dynamicCurrencyPoolHigh.length;
  const mediumActivePoolLength =
    poolCompositionMode === "fixed"
      ? fixedCurrencyPoolMedium.length
      : dynamicCurrencyPoolMedium.length;
  const lowActivePoolLength =
    poolCompositionMode === "fixed"
      ? fixedCurrencyPoolLow.length
      : dynamicCurrencyPoolLow.length;

  const noCurrenciesInPoolText = "\nNo currencies in this pool";
  const noFundsInPoolText = "\nNo funds in this pool";

  const highMcPoolRemarksText = useMemo(() => {
    if (
      highActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.high === 0
    )
      return undefined;
    if (
      highActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.high > 0
    )
      return undefined;
    if (
      highActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.high > 0
    )
      return noCurrenciesInPoolText;
    if (
      highActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.high === 0
    )
      return noFundsInPoolText;
  }, [highActivePoolLength, props, noCurrenciesInPoolText, noFundsInPoolText]);

  const mediumMcPoolRemarksText = useMemo(() => {
    if (
      mediumActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.medium === 0
    )
      return undefined;
    if (
      mediumActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.medium > 0
    )
      return undefined;
    if (
      mediumActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.medium > 0
    )
      return noCurrenciesInPoolText;
    if (
      mediumActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.medium === 0
    )
      return noFundsInPoolText;
  }, [
    mediumActivePoolLength,
    props,
    noCurrenciesInPoolText,
    noFundsInPoolText,
  ]);

  const lowMcPoolRemarksText = useMemo(() => {
    if (
      lowActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.low === 0
    )
      return undefined;
    if (
      lowActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.low > 0
    )
      return undefined;
    if (
      lowActivePoolLength === 0 &&
      props.strategy.header.mc_distribution.low > 0
    )
      return noCurrenciesInPoolText;
    if (
      lowActivePoolLength > 0 &&
      props.strategy.header.mc_distribution.low === 0
    )
      return noFundsInPoolText;
  }, [lowActivePoolLength, props, noCurrenciesInPoolText, noFundsInPoolText]);

  const highPoolIconSize = smallScreen ? "xs" : "sm";
  const mediumPoolIconSize = smallScreen ? "xs" : "sm";
  const lowPoolIconSize = smallScreen ? "xs" : "sm";

  const highPoolLabel =
    poolCompositionMode === "fixed"
      ? "High fixed pool"
      : "High Market Cap pool";
  const mediumPoolLabel =
    poolCompositionMode === "fixed"
      ? "Medium fixed pool"
      : "Medium Market Cap pool";
  const lowPoolLabel =
    poolCompositionMode === "fixed" ? "Low fixed pool" : "Low Market Cap pool";

  const highPoolSubLabel =
    poolCompositionMode === "fixed"
      ? "High"
      : `#1 - #${props.strategy.header.rank_distribution.high}`;
  const mediumPoolSubLabel =
    poolCompositionMode === "fixed"
      ? "Medium"
      : `#${props.strategy.header.rank_distribution.high + 1} - #${
          props.strategy.header.rank_distribution.medium
        }`;
  const lowPoolSubLabel =
    poolCompositionMode === "fixed"
      ? "Low"
      : `#${props.strategy.header.rank_distribution.medium + 1}+`;

  const renderDynamicPoolComposition = useCallback(
    (
      _currencies: Currency[],
      _size: MantineSize,
      previewAmount: number = 4
    ) => {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.5 }}
          className="currency-array-container"
        >
          {_currencies.length === 0 ? (
            <label className="dimmed-label">Empty</label>
          ) : (
            <CurrencyLogoArray
              currencies={_currencies}
              previewAmount={previewAmount}
              size={_size}
              expandable
              showNumber
              delay={500}
              onCurrencyClick={(currency) => setSelectedCurrency(currency)}
              selectedCurrency={selectedCurrency}
            />
          )}
        </motion.div>
      );
    },
    [setSelectedCurrency, selectedCurrency]
  );

  const arrowIconSize = 24;

  const [fixedPoolPopover, setFixedPoolPopover] = useState<string | undefined>(
    undefined
  );

  const maxFixedCurrencyAmount = 3;

  const renderFixedPoolComposition = useCallback(
    (
      _currencies: Currency[],
      _size: MantineSize,
      pool: "high" | "medium" | "low"
    ) => {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.5 }}
          className={"currency-array-container"}
        >
          {_currencies.length === 0 ? (
            <label className="dimmed-label">Empty</label>
          ) : (
            <>
              {_currencies
                .slice(0, maxFixedCurrencyAmount)
                .map((currency, idx) => (
                  <FixedCurrencyArrayItem
                    key={idx}
                    arrowIconSize={arrowIconSize}
                    currency={currency}
                    moveCurrencyToPool={moveCurrencyToPool}
                    pool={pool}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    size={_size}
                  />
                ))}
            </>
          )}
          {_currencies.length > maxFixedCurrencyAmount && (
            <Popover
              opened={fixedPoolPopover === pool}
              withArrow
              onClose={() => setFixedPoolPopover(undefined)}
              onMouseOver={() => setFixedPoolPopover(pool)}
              onMouseLeave={() => setFixedPoolPopover(undefined)}
              classNames={{
                inner: "notifications-popover-inner",
                target: "notifications-popover-target",
                arrow: "notifications-popover-arrow",
                body: "notifications-popover-body",
                root: "notifications-popover-root",
                wrapper: "notifications-popover-wrapper",
                popover: "notifications-popover-popover",
              }}
              position="bottom"
              placement="start"
              target={
                <label
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() =>
                    setFixedPoolPopover(
                      fixedPoolPopover === pool ? undefined : pool
                    )
                  }
                >
                  +{` ${_currencies.length - maxFixedCurrencyAmount}`}
                </label>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  maxWidth: 200,
                  gap: 15,
                  padding: 10,
                }}
              >
                {_currencies
                  .slice(maxFixedCurrencyAmount)
                  .map((currency, idx) => (
                    <FixedCurrencyArrayItem
                      key={idx}
                      arrowIconSize={arrowIconSize}
                      currency={currency}
                      moveCurrencyToPool={moveCurrencyToPool}
                      pool={pool}
                      selectedCurrency={selectedCurrency}
                      size={_size}
                      setSelectedCurrency={setSelectedCurrency}
                    />
                  ))}
              </div>
            </Popover>
          )}
        </motion.div>
      );
    },
    [
      moveCurrencyToPool,
      selectedCurrency,
      setSelectedCurrency,
      arrowIconSize,
      fixedPoolPopover,
      setFixedPoolPopover,
      maxFixedCurrencyAmount,
    ]
  );

  const initializeFixedPoolComposition =
    useCallback((): FixedPoolComposition => {
      const _initializedFixedPoolComposition = {
        high: dynamicCurrencyPoolHigh.map((currency) => currency.currency_name),
        medium: dynamicCurrencyPoolMedium.map(
          (currency) => currency.currency_name
        ),
        low: dynamicCurrencyPoolLow.map((currency) => currency.currency_name),
      };
      return _initializedFixedPoolComposition;
    }, [
      dynamicCurrencyPoolHigh,
      dynamicCurrencyPoolLow,
      dynamicCurrencyPoolMedium,
    ]);

  const poolAnimation = {
    opacity: 1,
    minWidth: "fit-content",
    maxWidth: "50%",
    flex: "1",
  };

  // ------------------------
  // NEW: Debounce logic for sliders
  // ------------------------

  // Funds distribution local states
  const [localFundsDistribution, setLocalFundsDistribution] = useState<
    [number, number]
  >([
    props.strategy.header.mc_distribution.high,
    props.strategy.header.mc_distribution.high +
      props.strategy.header.mc_distribution.medium,
  ]);

  const [debouncedFundsDistribution] = useDebouncedValue(
    localFundsDistribution,
    100
  );

  useEffect(() => {
    // Compare debounced with current props
    const { high, medium, low } = props.strategy.header.mc_distribution;
    const [debouncedHigh, debouncedMediumBoundary] = debouncedFundsDistribution;

    const newHigh = debouncedHigh;
    const newMedium = debouncedMediumBoundary - debouncedHigh;
    const newLow = 100 - debouncedMediumBoundary;

    if (newHigh !== high || newMedium !== medium || newLow !== low) {
      // Update only if there's a real change
      updateHeader({
        ...props.strategy.header,
        mc_distribution: {
          high: newHigh,
          medium: newMedium,
          low: newLow,
        },
      });
    }
  }, [debouncedFundsDistribution, props.strategy.header, updateHeader]);

  // MC Rank distribution local states
  const [localRankDistribution, setLocalRankDistribution] = useState<
    [number, number]
  >([
    props.strategy.header.rank_distribution.high,
    props.strategy.header.rank_distribution.medium,
  ]);

  const [debouncedRankDistribution] = useDebouncedValue(
    localRankDistribution,
    100
  );

  useEffect(() => {
    const { high, medium } = props.strategy.header.rank_distribution;
    const [debouncedHigh, debouncedMedium] = debouncedRankDistribution;

    if (high !== debouncedHigh || medium !== debouncedMedium) {
      updateHeader({
        ...props.strategy.header,
        rank_distribution: {
          high: debouncedHigh,
          medium: debouncedMedium,
        },
      });
    }
  }, [debouncedRankDistribution, props.strategy.header, updateHeader]);

  return (
    <div className="public-header-editor-outer-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5 }}
        className="public-header-editor-container"
      >
        <div className="public-header-row" id="funds-distribution-slider">
          <label className="dimmed-label public-header-label">
            Funds distribution
            <Tooltip
              className="public-header-info-tooltip"
              label={fundsDistribtutionTooltipDescription}
              position="bottom"
            >
              <AiOutlineInfoCircle size={tooltipIconSize} />
            </Tooltip>
          </label>
          <div className="slider-container">
            <RangeSlider
              label={null}
              min={0}
              max={100}
              minRange={0}
              value={localFundsDistribution}
              onChange={(value) => setLocalFundsDistribution(value)}
              labelAlwaysOn={false}
              radius={"xs"}
              color="green"
              styles={{
                thumb: {
                  height: 16,
                  width: 10,
                  borderWidth: 1,
                  borderRadius: 2,
                  borderColor: theme.orderOptionsTheme,
                  filter: "grayScale(0) !important",
                  backgroundColor: theme.orderOptionsTheme,
                },
                bar: {
                  backgroundColor: "transparent",
                },
                track: {
                  backgroundColor: theme.cardInterior,
                  borderRadius: "25%",
                  border: "1px solid " + theme.orderOptionsTheme,
                  filter: "grayScale(0.6)",
                },
              }}
              showLabelOnHover={false}
              size={"xs"}
            />
          </div>
        </div>

        {poolCompositionMode === "dynamic" && (
          <motion.div
            className="public-header-row"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <label className="dimmed-label public-header-label">
              MC Rank distribution
              <Tooltip
                wrapLines
                width={700}
                className="public-header-info-tooltip"
                label={rankDistributionTooltipDescription}
                position="bottom"
              >
                <AiOutlineInfoCircle size={tooltipIconSize} />
              </Tooltip>
            </label>
            <div className="slider-container">
              <RangeSlider
                min={1}
                minRange={2}
                value={localRankDistribution}
                onChange={(value) => setLocalRankDistribution(value)}
                labelAlwaysOn={false}
                radius={"xs"}
                color="cyan"
                styles={{
                  thumb: {
                    height: 16,
                    width: 10,
                    borderWidth: 1,
                    borderRadius: 2,
                    borderColor: theme.fragmentPattern,
                    filter: "grayScale(0) !important",
                    backgroundColor: theme.fragmentPattern,
                  },
                  bar: {
                    backgroundColor: "transparent",
                  },
                  track: {
                    backgroundColor: theme.cardInterior,
                    borderRadius: "25%",
                    border: "1px solid " + theme.fragmentPattern,
                    filter: "grayScale(0.2)",
                  },
                }}
                label={(value) => {
                  return `#${value}`;
                }}
                size={"xs"}
              />
            </div>
          </motion.div>
        )}
        <div className="public-header-row">
          <CommonButton
            activeTheme={props.activeTheme}
            id="pool-composition-switch"
            leftIcon={
              poolCompositionMode === "fixed" ? (
                <MdOutlineDynamicFeed />
              ) : (
                <MdDynamicForm />
              )
            }
            label={
              poolCompositionMode === "fixed" ? "Fixed pools" : "Dynamic pools"
            }
            onClick={() => {
              const fixedPoolComposition =
                props.strategy.header.fixed_pool_composition ??
                initializeFixedPoolComposition();
              updateHeader({
                ...props.strategy.header,
                pool_composition_type:
                  poolCompositionMode === "fixed" ? "dynamic" : "fixed",
                fixed_pool_composition: fixedPoolComposition,
              });
            }}
            compact
            flat
            borderTheme={
              poolCompositionMode === "fixed" ? "gray-accent" : "purple-accent"
            }
          />

          <div className="pool-display-container">
            <motion.div
              className={
                "pool-display" +
                (highMcPoolRemarksText
                  ? " remarks"
                  : highActivePoolLength === 0
                  ? " empty"
                  : "")
              }
              initial={{ opacity: 0 }}
              animate={poolAnimation}
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <Tooltip
                className="pool-display-label-container"
                position="bottom"
                label={
                  <>
                    <label>
                      <strong>{highPoolLabel}</strong>
                    </label>
                    <br />
                    {props.strategy.header.mc_distribution.high > 0 && (
                      <label>
                        <strong>
                          {(props.strategy.header.mc_distribution.high *
                            sampleAmount) /
                            100}
                          $
                        </strong>{" "}
                        to be allocated to this pool with a total of{" "}
                        <strong>{sampleAmount}$</strong>
                      </label>
                    )}
                    {highMcPoolRemarksText}
                  </>
                }
              >
                <div className="pool-display-label-row">
                  {/* <div className="pool-display-label-column">
                    {renderFundsDistributionControls("high")}
                  </div> */}
                  <div className="pool-display-label-column">
                    <CountUp
                      className={"wallet-stat-label"}
                      preserveValue
                      end={props.strategy.header.mc_distribution.high}
                      duration={1}
                      suffix="%"
                      decimals={0}
                    />
                    <label className="dimmed-label">{highPoolSubLabel}</label>
                  </div>
                </div>
              </Tooltip>
              {poolCompositionMode === "fixed"
                ? renderFixedPoolComposition(
                    fixedCurrencyPoolHigh,
                    highPoolIconSize,
                    "high"
                  )
                : renderDynamicPoolComposition(
                    dynamicCurrencyPoolHigh,
                    highPoolIconSize
                  )}
            </motion.div>
            <motion.div
              className={
                "pool-display" +
                (mediumMcPoolRemarksText
                  ? " remarks"
                  : mediumActivePoolLength === 0
                  ? " empty"
                  : "")
              }
              initial={{ opacity: 0 }}
              animate={poolAnimation}
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <Tooltip
                className="pool-display-label-container"
                position="bottom"
                label={
                  <>
                    <label>
                      <strong>{mediumPoolLabel}</strong>
                    </label>
                    <br />
                    {props.strategy.header.mc_distribution.medium > 0 && (
                      <label>
                        <strong>
                          {(props.strategy.header.mc_distribution.medium *
                            sampleAmount) /
                            100}
                          $
                        </strong>{" "}
                        to be allocated to this pool with a total of{" "}
                        <strong>{sampleAmount}$</strong>
                      </label>
                    )}
                    {mediumMcPoolRemarksText}
                  </>
                }
              >
                <div className="pool-display-label-row">
                  {/*  <div className="pool-display-label-column">
                    {renderFundsDistributionControls("medium")}
                  </div> */}
                  <div className="pool-display-label-column">
                    <CountUp
                      className={"wallet-stat-label"}
                      preserveValue
                      end={props.strategy.header.mc_distribution.medium}
                      duration={1}
                      suffix="%"
                      decimals={0}
                    />

                    <label className="dimmed-label">{mediumPoolSubLabel}</label>
                  </div>
                </div>
              </Tooltip>
              {poolCompositionMode === "fixed"
                ? renderFixedPoolComposition(
                    fixedCurrencyPoolMedium,
                    mediumPoolIconSize,
                    "medium"
                  )
                : renderDynamicPoolComposition(
                    dynamicCurrencyPoolMedium,
                    mediumPoolIconSize
                  )}
            </motion.div>
            <motion.div
              className={
                "pool-display" +
                (lowMcPoolRemarksText
                  ? " remarks"
                  : lowActivePoolLength === 0
                  ? " empty"
                  : "")
              }
              initial={{ opacity: 0 }}
              animate={poolAnimation}
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <Tooltip
                className="pool-display-label-container"
                position="bottom"
                label={
                  <>
                    <label>
                      <strong>{lowPoolLabel}</strong>
                    </label>
                    <br />
                    {props.strategy.header.mc_distribution.low > 0 && (
                      <label>
                        <strong>
                          {(props.strategy.header.mc_distribution.low *
                            sampleAmount) /
                            100}
                          $
                        </strong>{" "}
                        to be allocated to this pool with a total of{" "}
                        <strong>{sampleAmount}$</strong>
                      </label>
                    )}
                    {lowMcPoolRemarksText}
                  </>
                }
              >
                <div className="pool-display-label-row">
                  {/* <div className="pool-display-label-column">
                    {renderFundsDistributionControls("low")}
                  </div> */}
                  <div className="pool-display-label-column">
                    <CountUp
                      className={"wallet-stat-label"}
                      preserveValue
                      end={props.strategy.header.mc_distribution.low}
                      duration={1}
                      suffix="%"
                      decimals={0}
                    />
                    <label className="dimmed-label">{lowPoolSubLabel}</label>
                  </div>
                </div>
              </Tooltip>

              {poolCompositionMode === "fixed"
                ? renderFixedPoolComposition(
                    fixedCurrencyPoolLow,
                    lowPoolIconSize,
                    "low"
                  )
                : renderDynamicPoolComposition(
                    dynamicCurrencyPoolLow,
                    lowPoolIconSize
                  )}
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
