import "./achievement.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UsermanagerService } from "../../../services/UserManagerService";
import { useQuery } from "react-query";
import { Achievement } from "./Achievement";
import { motion } from "framer-motion";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { NeotonSpinner } from "../../custom-loaders/PixelLoader";

interface Props {
  activeTheme: string;
  user_hash: string | undefined;
  compact?: boolean;
}
export function UserAchievements(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [focusedAchievement, setFocusedAchievement] = useState<
    string | undefined
  >(undefined);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const fetchUserAchievements = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !props.user_hash) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.GetUserAchievements(
        token,
        props.user_hash
      );
      return response.data;
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, props.user_hash]);

  const achievementsQuery = useQuery(
    ["user_achievements", props.user_hash],
    fetchUserAchievements,
    {
      enabled: !!props.user_hash,
      cacheTime: 0, // 5 minutes
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const anyAchievements = useMemo(() => {
    if (!achievementsQuery?.data?.payload) return false;
    return achievementsQuery.data.payload.length > 0;
  }, [achievementsQuery?.data?.payload]);

  const checkScrollPosition = useCallback(() => {
    if (
      !achievementsQuery.data ||
      (achievementsQuery.data.payload?.length ?? 0) <= 2
    ) {
      setIsAtStart(true);
      setIsAtEnd(true);
      return;
    }
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
    }
  }, [achievementsQuery]);

  const scrollLeft = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: "smooth" });
      setTimeout(checkScrollPosition, 200); // Delay to allow smooth scrolling
    }
  }, [checkScrollPosition]);

  const scrollRight = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: "smooth" });
      setTimeout(checkScrollPosition, 200); // Delay to allow smooth scrolling
    }
  }, [checkScrollPosition]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", checkScrollPosition);

      // Check initial position
      checkScrollPosition();

      // Cleanup
      return () => {
        container.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, [checkScrollPosition]);

  return (
    <div className="user-achievements-outer">
      {loading ? (
        <div className="loading-achievements-container">
          <NeotonSpinner activeTheme={props.activeTheme} />
        </div>
      ) : (
        <>
          {anyAchievements ? (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isAtStart ? 0 : 1 }}
                transition={{ delay: 0.2 }}
                className="achievements-arrow-scroll left"
                onClick={scrollLeft}
              >
                <MdArrowBackIos />
              </motion.div>
              <div
                className={
                  "user-achievements" + (props.compact ? " compact" : "")
                }
                ref={containerRef}
              >
                {achievementsQuery?.data?.payload
                  ?.sort((a, b) => b.earned - a.earned)
                  .map((achievement, idx) => {
                    return (
                      <Achievement
                        key={idx}
                        userAchievement={achievement}
                        activeTheme={props.activeTheme}
                        focusedAchievementKey={focusedAchievement}
                        setFocusedAchievementKey={setFocusedAchievement}
                        compact={props.compact}
                        containerRef={containerRef}
                      />
                    );
                  })}
              </div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isAtEnd ? 0 : 1 }}
                transition={{ delay: 0.2 }}
                className="achievements-arrow-scroll right"
                onClick={scrollRight}
              >
                <MdArrowForwardIos />
              </motion.div>
            </>
          ) : (
            <div className="no-achievements-container">
              <motion.label
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.7 }}
                transition={{ delay: 0.2 }}
                className="dimmed-label"
              >
                No achievements yet
              </motion.label>
            </div>
          )}
        </>
      )}
    </div>
  );
}
