import { useMemo } from "react";
import { PageQuickstartGuide } from "../../services/BlueprintService";
import "./quickstart-guide.scss";
import { motion } from "framer-motion";
interface Props {
  quickstartGuide: PageQuickstartGuide;
  parentRef: React.RefObject<HTMLDivElement>;
}
export function QuickstartGuide(props: React.PropsWithChildren<Props>) {
  const boxWidth = useMemo(() => {
    if (props.parentRef.current) {
      return (
        props.parentRef.current.clientWidth /
        Object.keys(props.quickstartGuide).length
      );
    }
    return 100;
  }, [props.quickstartGuide, props.parentRef]);

  return (
    <motion.div
      initial={{ scaleX: 0 }}
      animate={{ scaleX: 1 }}
      transition={{ duration: 0.2, delay: .5 }}
      className="quickstart-guide-container"
    >
      <div className="quickstart-guide">
        {Object.keys(props.quickstartGuide).map((key, index) => {
          const item = props.quickstartGuide[key];
          return (
            <div
              key={index}
              className="quickstart-guide-step"
              style={{
                minWidth: 250,
                width: boxWidth,
              }}
            >
              <div className="quickstart-guide-step-header">
                <label>
                  {index + 1}. {item.header}
                </label>
              </div>
              <div className="quickstart-guide-step-description">
                <label>{item.description}</label>
              </div>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
}
