import { Popover, Slider, Space, Tooltip } from "@mantine/core";
import { AiOutlinePercentage } from "react-icons/ai";
import {
  Case,
  StoplossType,
  TakeProfitType,
} from "../../../interfaces/strategyInterfaces/Strategy";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UpdateSelectedCaseContext } from "../../../pages/strategy-page/StrategyPage";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { BiSolidDownArrow } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { EquationContext } from "react-equation";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { CommonCandleSizeControl } from "../../common/common-candle-size-control/CommonCandleSizeControl";
import { FiMoreHorizontal } from "react-icons/fi";
import { getTheme } from "../../../utils/themeUtil";
import { RiExchangeBoxLine } from "react-icons/ri";
import { useDebouncedValue } from "@mantine/hooks";

interface Props {
  activeTheme: string;
  case: Case;
  readonly?: boolean;
}

export function CaseExtras(props: React.PropsWithChildren<Props>) {
  const updateCase = useContext(UpdateSelectedCaseContext);
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);

  const [takeProfitMenuOpened, setTakeProfitMenuOpened] = useState(false);
  const [stoplossMenuOpened, setStoplossMenuOpened] = useState(false);

  const enforceOrderWeightRules = useCallback((value: number) => {
    if (value < 0.5) return 0.5;
    if (value > 1) return 1;
    return value;
  }, []);

  const enforceOrderFrequencyRules = useCallback((value: number) => {
    if (value < 1) return 1;
    if (value > 24) return 24;
    return value;
  }, []);

  // Local states for sliders
  const [orderWeightLocal, setOrderWeightLocal] = useState(props.case.weight);
  const [orderFrequencyLocal, setOrderFrequencyLocal] = useState(
    props.case.daily_frequency_limit ?? 1
  );

  // Min holding period steps
  const minHoldingPeriodSteps = useMemo(() => {
    return [
      300000, // 5 minutes
      1800000, // 30 minutes
      3600000, // 1 hour
      7200000, // 2 hours
      14400000, // 4 hours
      21600000, // 6 hours
      28800000, // 8 hours
      43200000, // 12 hours
      64800000, // 18 hours
      86400000, // 1 day
      259200000, // 3 days
      604800000, // 7 days
    ];
  }, []);

  const initialMinHoldingIndex = useMemo(() => {
    const current =
      props.case.constraints?.sell.min_holding_period ??
      minHoldingPeriodSteps[0];
    const idx = minHoldingPeriodSteps.findIndex((step) => step === current);
    return idx === -1 ? 0 : idx;
  }, [props.case.constraints?.sell.min_holding_period, minHoldingPeriodSteps]);

  const [minHoldingPeriodIndexLocal, setMinHoldingPeriodIndexLocal] = useState(
    initialMinHoldingIndex
  );

  // Debounce the local values
  const [debouncedWeight] = useDebouncedValue(orderWeightLocal, 200);
  const [debouncedFrequency] = useDebouncedValue(orderFrequencyLocal, 200);
  const [debouncedMinHoldingPeriodIndex] = useDebouncedValue(
    minHoldingPeriodIndexLocal,
    200
  );

  // Update global state only if there's an actual change
  useEffect(() => {
    if (!props.readonly) {
      const newWeight = enforceOrderWeightRules(debouncedWeight);
      if (props.case.weight !== newWeight) {
        updateCase({
          ...props.case,
          weight: newWeight,
        });
      }
    }
  }, [
    debouncedWeight,
    enforceOrderWeightRules,
    props.case,
    props.readonly,
    updateCase,
  ]);

  useEffect(() => {
    if (!props.readonly) {
      const newFrequency = enforceOrderFrequencyRules(debouncedFrequency);
      if (props.case.daily_frequency_limit !== newFrequency) {
        updateCase({
          ...props.case,
          daily_frequency_limit: newFrequency,
        });
      }
    }
  }, [
    debouncedFrequency,
    enforceOrderFrequencyRules,
    props.case,
    props.readonly,
    updateCase,
  ]);

  // Debounced update for min holding period
  useEffect(() => {
    if (!props.readonly) {
      const current =
        props.case.constraints?.sell.min_holding_period ??
        minHoldingPeriodSteps[0];
      const newVal = minHoldingPeriodSteps[debouncedMinHoldingPeriodIndex];
      if (current !== newVal) {
        updateCase({
          ...props.case,
          constraints: {
            ...props.case.constraints,
            sell: {
              ...props.case.constraints?.sell,
              min_holding_period: newVal,
            },
          },
        });
      }
    }
  }, [
    debouncedMinHoldingPeriodIndex,
    props.case,
    props.readonly,
    updateCase,
    minHoldingPeriodSteps,
  ]);

  const orderWeightPctValue = (props.case.weight * 100).toFixed(0);

  const handleTakeProfitChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateCase({
        ...props.case,
        stop_profit:
          value && value !== "" ? Math.abs(Number(value)) : undefined,
      });
    },
    [props.case, updateCase]
  );

  const handleStoplossChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateCase({
        ...props.case,
        stoploss: value && value !== "" ? -Math.abs(Number(value)) : undefined,
      });
    },
    [props.case, updateCase]
  );

  const handleAtrStoplossMultiplierChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateCase({
        ...props.case,
        atr_stoploss_multiplier: value && value !== "" ? Number(value) : 2,
      });
    },
    [props.case, updateCase]
  );

  const handleAtrTakeProfitMultiplierChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateCase({
        ...props.case,
        atr_take_profit_multiplier: value && value !== "" ? Number(value) : 2,
      });
    },
    [props.case, updateCase]
  );

  const handleTakeProfitCandleSizeChange = useCallback(
    (value: string) => {
      updateCase({
        ...props.case,
        stop_profit_candle_size: value,
      });
    },
    [props.case, updateCase]
  );

  const handleStoplossCandleSizeChange = useCallback(
    (value: string) => {
      updateCase({
        ...props.case,
        stoploss_candle_size: value,
      });
    },
    [props.case, updateCase]
  );

  const getReactionTimeLabels = useCallback(
    (candleSizeString: string | undefined) => {
      if (!candleSizeString) return "None";
      if (candleSizeString === "disabled") return "Never";
      if (candleSizeString.includes("1D")) {
        return "Every 24 hours";
      }
      if (candleSizeString.includes("1h")) {
        return "Every hour";
      }
      if (candleSizeString.includes("5m")) {
        return "Every 5 minutes";
      }
    },
    []
  );

  const getTakeProfitLabel = useCallback(
    (_takeProfitType: TakeProfitType | undefined) => {
      switch (_takeProfitType) {
        case "static":
          return "Static take profit";
        case "atr":
          return "ATR take profit";
        case "disabled":
          return "No take profit";
        default:
          return "No take profit";
      }
    },
    []
  );

  const getStoplossLabel = useCallback(
    (_stoplossType: StoplossType | undefined) => {
      switch (_stoplossType) {
        case "static":
          return "Static stoploss";
        case "atr":
          return "ATR stoploss";
        case "trailing_atr":
          return "Trailing ATR";
        case "trailing":
          return "Trailing stoploss";
        case "disabled":
          return "No stoploss";
        default:
          return "No stoploss";
      }
    },
    []
  );

  const getHoldingPeriodLabel = useCallback((milliseconds) => {
    switch (milliseconds) {
      case 300000:
        return "5 minutes";
      case 1800000:
        return "30 minutes";
      case 3600000:
        return "1 hour";
      case 7200000:
        return "2 hours";
      case 14400000:
        return "4 hours";
      case 21600000:
        return "6 hours";
      case 28800000:
        return "8 hours";
      case 43200000:
        return "12 hours";
      case 64800000:
        return "18 hours";
      case 86400000:
        return "1 day";
      case 259200000:
        return "3 days";
      case 604800000:
        return "7 days";
      default:
        return "Unknown period";
    }
  }, []);

  const [moreOptionsPopoverOpened, setMoreOptionsPopoverOpened] =
    useState(false);
  const moreOrderOptionsControls = useCallback(() => {

    return (
      <Popover
        style={{ alignSelf: "center" }}
        classNames={{
          inner: "notifications-popover-inner",
          target: "notifications-popover-target",
          arrow: "notifications-popover-arrow",
          body: "notifications-popover-body",
          root: "notifications-popover-root",
          wrapper: "notifications-popover-wrapper",
          popover: "notifications-popover-popover",
        }}
        shadow="xl"
        opened={moreOptionsPopoverOpened}
        onClose={() => setMoreOptionsPopoverOpened(false)}
        target={
          <CommonIconButton
            activeTheme={props.activeTheme}
            label="More options"
            onClick={() =>
              setMoreOptionsPopoverOpened(!moreOptionsPopoverOpened)
            }
            flat
            style={{ fontSize: "smaller", alignSelf: "center" }}
            icon={<FiMoreHorizontal />}
            borderTheme={"blue-accent"}
            tooltipPosition="left"
          />
        }
      >
        <div className="more-order-options-container">
          <label className="dimmed-label">Minimum holding period</label>
          <label style={{ alignSelf: "center" }}>
            {getHoldingPeriodLabel(
              minHoldingPeriodSteps[debouncedMinHoldingPeriodIndex]
            )}
          </label>
          {!props.readonly && (
            <Slider
              min={0}
              max={minHoldingPeriodSteps.length - 1}
              step={1}
              value={minHoldingPeriodIndexLocal}
              onChange={setMinHoldingPeriodIndexLocal}
              showLabelOnHover={true}
              label={(value) =>
                getHoldingPeriodLabel(minHoldingPeriodSteps[value])
              }
              styles={{
                thumb: {
                  height: 16,
                  width: 6,
                  borderWidth: 1,
                  borderRadius: 2,
                  borderColor: theme.orderOptionsTheme,
                  filter: "grayScale(0) !important",
                  backgroundColor: theme.orderOptionsTheme,
                },
                bar: { backgroundColor: "transparent" },
                track: {
                  backgroundColor: theme.cardInterior,
                  borderRadius: "10%",
                  border: "1px solid " + theme.orderOptionsTheme,
                  filter: "grayScale(0.6)",
                },
              }}
            />
          )}
        </div>
      </Popover>
    );
  }, [
    props.readonly,
    props.activeTheme,
    theme,
    minHoldingPeriodSteps,
    getHoldingPeriodLabel,
    minHoldingPeriodIndexLocal,
    setMinHoldingPeriodIndexLocal,
    debouncedMinHoldingPeriodIndex,
    setMoreOptionsPopoverOpened,
    moreOptionsPopoverOpened,
  ]);

  const getOrderWeightNumberInput = () => {
    return (
      <div className="number-input-column">
        <div className="number-input-container" id="order-weight-input">
          <Tooltip
            label={
              <label>
                This case will use <strong>{orderWeightPctValue}%</strong> of
                the funds in the market cap pool
              </label>
            }
          >
            <label className="dimmed-label">Order weight:</label>
          </Tooltip>
          <Space w="xs" />
          <label>{orderWeightPctValue}</label>
          <AiOutlinePercentage />
        </div>
        {!props.readonly && (
          <Slider
            min={0.5}
            max={1}
            step={0.05}
            showLabelOnHover={false}
            value={orderWeightLocal}
            onChange={setOrderWeightLocal}
            label={(value) => `${(value * 100).toFixed(0)}%`}
            styles={{
              thumb: {
                height: 16,
                width: 6,
                borderWidth: 1,
                borderRadius: 2,
                borderColor: theme.orderOptionsTheme,
                filter: "grayScale(0) !important",
                backgroundColor: theme.orderOptionsTheme,
              },
              bar: { backgroundColor: "transparent" },
              track: {
                backgroundColor: theme.cardInterior,
                borderRadius: "8%",
                border: "1px solid " + theme.orderOptionsTheme,
                filter: "grayScale(0.6)",
              },
            }}
          />
        )}
      </div>
    );
  };

  const getOrderFrequencyNumberInput = () => {
    return (
      <div className="number-input-column">
        <div className="number-input-container" id="order-frequency-input">
          <Tooltip
            label={
              <label>
                This case can execute new buy orders{" "}
                <strong>{props.case.daily_frequency_limit}</strong> times per
                day
              </label>
            }
          >
            <label className="dimmed-label">Order frequency:</label>
          </Tooltip>
          <Space w="xs" />
          <label>{props.case.daily_frequency_limit} / day</label>
        </div>
        {!props.readonly && (
          <Slider
            min={1}
            max={24}
            step={1}
            showLabelOnHover={false}
            value={orderFrequencyLocal}
            onChange={setOrderFrequencyLocal}
            label={(value) => `${value}/day`}
            styles={{
              thumb: {
                height: 16,
                width: 6,
                borderWidth: 1,
                borderRadius: 2,
                borderColor: theme.orderOptionsTheme,
                filter: "grayScale(0) !important",
                backgroundColor: theme.orderOptionsTheme,
              },
              bar: { backgroundColor: "transparent" },
              track: {
                backgroundColor: theme.cardInterior,
                borderRadius: "8%",
                border: "1px solid " + theme.orderOptionsTheme,
                filter: "grayScale(0.6)",
              },
            }}
          />
        )}
      </div>
    );
  };

  const getTakeProfitNumberInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stop_profit && props.case.stop_profit > 0
            ? "stop-profit-input"
            : ""
        }
      >
        <label>Change:</label>
        <Space w={"xs"} />
        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                if (props.readonly) return;
                const nextTakeProfit = props.case.stop_profit
                  ? props.case.stop_profit + 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stop_profit: Math.abs(nextTakeProfit),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleTakeProfitChange}
            value={props.case.stop_profit}
            className="extras-number-input"
            type="number"
            placeholder="0"
            id={
              props.case.stop_profit && props.case.stop_profit > 0
                ? "stop-profit-input"
                : ""
            }
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                if (props.readonly) return;
                const nextTakeProfit = props.case.stop_profit
                  ? props.case.stop_profit - 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stop_profit: Math.abs(nextTakeProfit),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>
        <AiOutlinePercentage />
      </div>
    );
  }, [props.case, props.readonly, handleTakeProfitChange, updateCase]);

  const getTakeProfitAtrInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stop_profit && props.case.stop_profit > 0
            ? "stop-profit-input"
            : ""
        }
      >
        <label>ATR Multiplier:</label>
        <Space w={"xs"} />
        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                if (props.readonly) return;
                const nextMultiplier = props.case.atr_take_profit_multiplier
                  ? props.case.atr_take_profit_multiplier + 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_take_profit_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleAtrTakeProfitMultiplierChange}
            value={parseFloat(
              props.case.atr_take_profit_multiplier?.toFixed(1) ?? "2.0"
            )}
            className="extras-number-input"
            type="number"
            min={0.1}
            max={100}
            placeholder="0"
            id={
              props.case.stop_profit && props.case.stop_profit > 0
                ? "stop-profit-input"
                : ""
            }
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                if (props.readonly) return;
                const nextMultiplier = props.case.atr_take_profit_multiplier
                  ? props.case.atr_take_profit_multiplier - 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_take_profit_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>
      </div>
    );
  }, [
    props.case,
    props.readonly,
    handleAtrTakeProfitMultiplierChange,
    updateCase,
  ]);

  const getStoplossInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stoploss && props.case.stoploss < 0 ? "stoploss-input" : ""
        }
      >
        <label>Change: </label>
        <Space w={"xs"} />
        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                const nextStoploss = props.case.stoploss
                  ? props.case.stoploss - 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stoploss: -Math.abs(nextStoploss),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleStoplossChange}
            value={props.case.stoploss}
            className="extras-number-input"
            type="number"
            placeholder="0"
            id={
              props.case.stoploss && props.case.stoploss < 0
                ? "stoploss-input"
                : ""
            }
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                const nextStoploss = props.case.stoploss
                  ? props.case.stoploss + 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stoploss: -Math.abs(nextStoploss),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>
        <AiOutlinePercentage />
      </div>
    );
  }, [props.case, props.readonly, handleStoplossChange, updateCase]);

  const getStoplossAtrInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stoploss && props.case.stoploss < 0 ? "stoploss-input" : ""
        }
      >
        <label>ATR Multiplier:</label>
        <Space w={"xs"} />
        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                const nextMultiplier = props.case.atr_stoploss_multiplier
                  ? props.case.atr_stoploss_multiplier + 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_stoploss_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleAtrStoplossMultiplierChange}
            value={parseFloat(
              props.case.atr_stoploss_multiplier?.toFixed(1) ?? "2.0"
            )}
            min={0.1}
            max={100}
            className="extras-number-input"
            type="number"
            placeholder="0"
            id={"stoploss-input"}
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                const nextMultiplier = props.case.atr_stoploss_multiplier
                  ? props.case.atr_stoploss_multiplier - 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_stoploss_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>
      </div>
    );
  }, [
    props.case,
    props.readonly,
    handleAtrStoplossMultiplierChange,
    updateCase,
  ]);

  const stoplossEquations = useMemo(() => {
    const multiplierValue =
      props.case.atr_stoploss_multiplier?.toFixed(1) ?? "2";
    const _stoploss_equations: { [key: string]: string } = {
      atr: `Current Price < Buy Price - (ATR * ${multiplierValue})`,
      trailing_atr: `Current Price < Peak Price - (ATR * ${multiplierValue})`,
    };
    return _stoploss_equations;
  }, [props.case]);

  const takeProfitEquations = useMemo(() => {
    const multiplierValue =
      props.case.atr_take_profit_multiplier?.toFixed(1) ?? "2";
    const _take_profit_equations: { [key: string]: string } = {
      atr: `Current Price > Buy Price + (ATR * ${multiplierValue})`,
    };
    return _take_profit_equations;
  }, [props.case]);

  return (
    <div className="case-extras-outer-container">
      <label className="dimmed-label">
        Immediate actions. Triggers regardless of criterias.
      </label>
      <div className="case-extras-container">
        {/* Take Profit Column */}
        <div className={"extras-column take-profit"}>
          <div className="extras-column-header">
            <CommonButton
              activeTheme={props.activeTheme}
              label={getTakeProfitLabel(props.case.take_profit_type)}
              onClick={() => setTakeProfitMenuOpened(!takeProfitMenuOpened)}
              compact
              disabled={props.readonly}
              flat={!takeProfitMenuOpened}
              leftIcon={<AiOutlinePercentage />}
              borderTheme={
                !props.case.take_profit_type ||
                props.case.take_profit_type === "disabled"
                  ? "gray-accent"
                  : "green-accent"
              }
            />
          </div>
          <div className="extras-column-body">
            {takeProfitMenuOpened ? (
              <>
                <BiSolidDownArrow size={8} />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getTakeProfitLabel("static")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      take_profit_type: "static",
                    });
                    setTakeProfitMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"green-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getTakeProfitLabel("atr")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      take_profit_type: "atr",
                      atr_take_profit_multiplier:
                        props.case.atr_take_profit_multiplier ?? 2,
                    });
                    setTakeProfitMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"green-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getTakeProfitLabel("disabled")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      take_profit_type: "disabled",
                    });
                    setTakeProfitMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"gray-accent"}
                />
              </>
            ) : (
              <>
                {!props.case.take_profit_type ||
                props.case.take_profit_type === "disabled" ? (
                  <label className="dimmed-label">No take profit</label>
                ) : (
                  <>
                    {props.case.take_profit_type === "static" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <label className="dimmed-label">
                            % Change since buy
                          </label>
                          {getTakeProfitNumberInput()}
                        </div>
                      </div>
                    )}
                    {props.case.take_profit_type === "atr" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <EquationContext
                            render={(renderEquation) => (
                              <div
                                className="equation-wrapper"
                                style={{ opacity: 0.5 }}
                              >
                                {renderEquation(takeProfitEquations["atr"])}
                              </div>
                            )}
                          />
                          {getTakeProfitAtrInput()}
                        </div>
                      </div>
                    )}
                    <div className="reaction-time-container">
                      <label className="dimmed-label">
                        Reaction time:{" "}
                        {getReactionTimeLabels(
                          props.case.stop_profit_candle_size
                        )}
                      </label>
                      {!props.readonly && (
                        <CommonCandleSizeControl
                          selectedCandleSize={
                            props.case.stop_profit_candle_size?.replace(
                              "candle_size_",
                              ""
                            ) ?? "1D"
                          }
                          onClick={(candleSize) =>
                            handleTakeProfitCandleSizeChange(
                              `candle_size_${candleSize}`
                            )
                          }
                          themeColor="buy"
                          hideLabel
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {/* Stoploss Column */}
        <div className={"extras-column stoploss"}>
          <div className="extras-column-header">
            <CommonButton
              activeTheme={props.activeTheme}
              label={getStoplossLabel(props.case.stoploss_type)}
              onClick={() => setStoplossMenuOpened(!stoplossMenuOpened)}
              compact
              disabled={props.readonly}
              flat={!stoplossMenuOpened}
              leftIcon={<AiOutlinePercentage />}
              borderTheme={
                !props.case.stoploss_type ||
                props.case.stoploss_type === "disabled"
                  ? "gray-accent"
                  : "red-accent"
              }
            />
          </div>
          <div className="extras-column-body">
            {stoplossMenuOpened ? (
              <>
                <BiSolidDownArrow size={8} />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("static")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "static",
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("trailing")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "trailing",
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("atr")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "atr",
                      atr_stoploss_multiplier:
                        props.case.atr_stoploss_multiplier ?? 2,
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("trailing_atr")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "trailing_atr",
                      atr_stoploss_multiplier:
                        props.case.atr_stoploss_multiplier ?? 2,
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("disabled")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "disabled",
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"gray-accent"}
                />
              </>
            ) : (
              <>
                {!props.case.stoploss_type ||
                props.case.stoploss_type === "disabled" ? (
                  <label className="dimmed-label">No stoploss</label>
                ) : (
                  <>
                    {props.case.stoploss_type === "trailing" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <label className="dimmed-label">
                            % Change since peak holding price
                          </label>
                          {getStoplossInput()}
                        </div>
                      </div>
                    )}
                    {props.case.stoploss_type === "static" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <label className="dimmed-label">
                            % Change since buy
                          </label>
                          {getStoplossInput()}
                        </div>
                      </div>
                    )}
                    {props.case.stoploss_type === "atr" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <EquationContext
                            render={(renderEquation) => (
                              <div
                                className="equation-wrapper"
                                style={{ opacity: 0.5 }}
                              >
                                {renderEquation(stoplossEquations["atr"])}
                              </div>
                            )}
                          />
                          {getStoplossAtrInput()}
                        </div>
                      </div>
                    )}
                    {props.case.stoploss_type === "trailing_atr" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <EquationContext
                            render={(renderEquation) => (
                              <div
                                className="equation-wrapper"
                                style={{ opacity: 0.5 }}
                              >
                                {renderEquation(
                                  stoplossEquations["trailing_atr"]
                                )}
                              </div>
                            )}
                          />
                          {getStoplossAtrInput()}
                        </div>
                      </div>
                    )}
                    <div className="reaction-time-container">
                      <label className="dimmed-label">
                        Reaction time:{" "}
                        {getReactionTimeLabels(props.case.stoploss_candle_size)}
                      </label>
                      {!props.readonly && (
                        <CommonCandleSizeControl
                          selectedCandleSize={
                            props.case.stoploss_candle_size?.replace(
                              "candle_size_",
                              ""
                            ) ?? "1D"
                          }
                          onClick={(candleSize) =>
                            handleStoplossCandleSizeChange(
                              `candle_size_${candleSize}`
                            )
                          }
                          themeColor="sell"
                          hideLabel
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <label className="dimmed-label">Order options</label>
      <div className={"case-extras-container order-options"}>
        <div className={"extras-column order-options"}>
          <div className="extras-column-body order-options">
            {getOrderWeightNumberInput()}
            {getOrderFrequencyNumberInput()}
            {moreOrderOptionsControls()}
          </div>
        </div>
      </div>
    </div>
  );
}
