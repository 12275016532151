import { Avatar, MantineSize, Tooltip } from "@mantine/core";
import { Currency } from "../../interfaces/Currency";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { getCurrencyUrl } from "../../utils/cdnUtil";
import { SetSelectedCurrencyContext } from "../shared-context/HandleCurrencyContext";
import { useContext } from "react";

interface Props {
  selectedCurrency: Currency | undefined;
  currency: Currency;
  size: MantineSize;
  pool: string;
  arrowIconSize: number;
  moveCurrencyToPool: (
    currency: Currency,
    from_pool: string,
    to_pool: string
  ) => void;
  setSelectedCurrency: (currency: Currency) => void;
  delay?: number;
}
export function FixedCurrencyArrayItem(props: React.PropsWithChildren<Props>) {
  return (
    <div className="fixed-currency-array-item">
      <Tooltip
        className={
          "currency-array-item" +
          (props.currency.currency_name ===
          props.selectedCurrency?.currency_name
            ? " selected"
            : "")
        }
        label={`#${props.currency.cmc_rank} ${props.currency.currency_name}`}
      >
        <Avatar
          src={getCurrencyUrl(props.currency)}
          size={props.size}
          radius={"lg"}
        />
      </Tooltip>
      <div className="fixed-currency-controls">
        <Tooltip
          disabled={props.pool === "high"}
          style={
            props.pool === "high"
              ? {
                  zIndex: 0,
                }
              : {}
          }
          gutter={20}
          position="left"
          placement="end"
          withArrow
          label={
            <label>
              Move <strong>{props.currency.currency_name}</strong> to the{" "}
              <strong>{props.pool === "medium" ? "high" : "medium"}</strong>{" "}
              pool
            </label>
          }
        >
          <div
            style={
              props.pool === "high" ? { pointerEvents: "none", opacity: 0 } : {}
            }
            className="fixed-currency-control-button left"
            onClick={() => {
              props.moveCurrencyToPool(
                props.currency,
                props.pool,
                props.pool === "medium" ? "high" : "medium"
              );
            }}
          >
            <IoIosArrowBack size={props.arrowIconSize} />
          </div>
        </Tooltip>
        <div
          onClick={() => props.setSelectedCurrency(props.currency)}
          className="currency-shortcut-button"
        />
        <Tooltip
          delay={props.delay}
          disabled={props.pool === "low"}
          style={
            props.pool === "low"
              ? {
                  zIndex: 0,
                }
              : {}
          }
          gutter={20}
          position="right"
          placement="end"
          withArrow
          label={
            <label>
              Move <strong>{props.currency.currency_name}</strong> to the{" "}
              <strong>{props.pool === "high" ? "medium" : "low"}</strong> pool
            </label>
          }
        >
          <div
            style={
              props.pool === "low" ? { pointerEvents: "none", opacity: 0 } : {}
            }
            className="fixed-currency-control-button right"
            onClick={() => {
              props.moveCurrencyToPool(
                props.currency,
                props.pool,
                props.pool === "high" ? "medium" : "low"
              );
            }}
          >
            <IoIosArrowForward size={props.arrowIconSize} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
