import "./sidemenu.scss";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserPermissions } from "../../interfaces/UserPermissions";
import { useMediaQuery } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { UsermanagerService } from "../../services/UserManagerService";
import { useAuth0 } from "@auth0/auth0-react";
import { ProfileEditor } from "../../pages/home-page/home-page-cards/ProfileEditor";
import { AcceptModalContent } from "../legal/AcceptModal";
import { PrivacyPolicy } from "../legal/PrivacyPolicy";
import { TermsOfService } from "../legal/TermsOfService";
import { motion } from "framer-motion";
import { IoLogInOutline } from "react-icons/io5";
import { RiUserAddFill } from "react-icons/ri";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { useLocation } from "react-router-dom";
import { EmailVerificationPopup } from "./EmailVerificationPopup";
import {
  RefetchUserInfoContext,
  UpdateCurrencyVibranceContext,
  UserInfoContext,
} from "../../App";
import { useQuery } from "react-query";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";

interface Props {
  activeTheme: string;
  userPermissions: UserPermissions;
  isAuthenticated: boolean;
  handleSwitchTheme: (newTheme: "light" | "dark" | "night") => void;
  clearCache: () => void;
}

const MenuRoutes = React.lazy(() => import("./Routes"));
const UserPopupMenu = React.lazy(() => import("./UserPopupMenu"));

export function Sidemenu(props: React.PropsWithChildren<Props>): JSX.Element {
  const compact = useMediaQuery("(max-width: 768px)");
  const superNarrow = useMediaQuery("(max-width: 440px)");
  const { getAccessTokenSilently, loginWithRedirect, isLoading } = useAuth0();
  const location = useLocation();
  const userInfo = useContext(UserInfoContext);
  const refetchUserInfo = useContext(RefetchUserInfoContext);

  const [popupMenuOpened, setPopupMenuOpened] = useState(false);
  const [tosModalOpened, setTosModalOpened] = useState(false);
  const [privacyModalOpened, setPrivacyModalOpened] = useState(false);
  const [emailVerificationModalOpened, setEmailVerificationModalOpened] =
    useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [acceptingTos, setAcceptingTos] = useState(false);
  const [acceptingPrivacy, setAcceptingPrivacy] = useState(false);
  const [didPromptEmailVerification, setDidPromptEmailVerification] =
    useState(false);

  const hasPermissions =
    props.userPermissions &&
    props.userPermissions.includedPermissions?.includes("free")
      ? true
      : false;

  const fetchCurrencies = useCallback(async () => {
    const _token = await getAccessTokenSilently();
    try {
      return await UsermanagerService.blueprint.getCurrencies(_token);
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const updateCurrencyVibrances = useContext(UpdateCurrencyVibranceContext);

  const currenciesQuery = useQuery("Currencies", fetchCurrencies, {
    keepPreviousData: true,
    cacheTime: 120000,
    staleTime: 120000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retryDelay: 4000,
    onSuccess: (data) => {
      if (!data || !updateCurrencyVibrances) return;
      updateCurrencyVibrances(data);
    },
  });

  const handleAcceptTos = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setAcceptingTos(true);
      await UsermanagerService.AcceptTermsOfService(token);
      refetchUserInfo();
      if (!hasPermissions) {
        window.location.reload();
      }
    } finally {
      setAcceptingTos(false);
      setTosModalOpened(false);
    }
  }, [
    getAccessTokenSilently,
    refetchUserInfo,
    setAcceptingTos,
    setTosModalOpened,
    hasPermissions,
  ]);

  const handleAcceptPrivacy = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setAcceptingPrivacy(true);
      await UsermanagerService.AcceptPrivacyPolicy(token);
      refetchUserInfo();
      if (!hasPermissions) {
        window.location.reload();
      }
    } finally {
      setAcceptingPrivacy(false);
      setPrivacyModalOpened(false);
    }
  }, [
    getAccessTokenSilently,
    refetchUserInfo,
    setAcceptingPrivacy,
    setPrivacyModalOpened,
    hasPermissions,
  ]);

  useEffect(() => {
    if (!props.isAuthenticated || !userInfo) return;

    if (userInfo?.username === null && !modalOpened) {
      setModalOpened(true);
    }

    if (userInfo?.email_verified === false && !didPromptEmailVerification) {
      setEmailVerificationModalOpened(true);
      return;
    }

    if (userInfo?.privacy_policy_accepted === false) {
      setPrivacyModalOpened(true);
      return;
    }

    if (userInfo?.terms_of_service_accepted === false) {
      setTosModalOpened(true);
      return;
    }
  }, [
    userInfo,
    setModalOpened,
    modalOpened,
    props.isAuthenticated,
    setEmailVerificationModalOpened,
    setPrivacyModalOpened,
    setTosModalOpened,
    didPromptEmailVerification,
  ]);
  const menuRoutes = useCallback(
    () => (
      <MenuRoutes
        isAuthenticated={props.isAuthenticated}
        userPermissions={props.userPermissions}
        open={popupMenuOpened}
        setOpened={setPopupMenuOpened}
      />
    ),
    [
      props.isAuthenticated,
      props.userPermissions,
      popupMenuOpened,
      setPopupMenuOpened,
    ]
  );

  const userPopupMenu = useCallback(
    () => (
      <UserPopupMenu
        setModalOpened={setModalOpened}
        activeTheme={props.activeTheme}
        handleSwitchTheme={props.handleSwitchTheme}
        isAuthenticated={props.isAuthenticated}
        clearCache={props.clearCache}
        compact={compact}
        openMenu={popupMenuOpened}
      />
    ),
    [
      setModalOpened,
      props.activeTheme,
      props.handleSwitchTheme,
      props.isAuthenticated,
      props.clearCache,
      compact,
      popupMenuOpened,
    ]
  );

  const landingPageEntryPoints = useMemo(() => {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: 1,
          scale: 1,
          position: "absolute",
          bottom: superNarrow ? "10%" : "5%",
          left: superNarrow ? "30%" : "2%",
          zIndex: 99,
        }}
        transition={{ duration: 0.25, delay: 1.5 }}
        exit={{ opacity: 0 }}
        className="landing-page-entry-points"
      >
        <CommonButton
          compact={!superNarrow}
          activeTheme={props.activeTheme}
          label="Log in"
          leftIcon={<IoLogInOutline />}
          onClick={() => {
            loginWithRedirect();
          }}
        />
        <CommonButton
          compact={!superNarrow}
          activeTheme={props.activeTheme}
          label="Sign up"
          leftIcon={<RiUserAddFill />}
          onClick={() => {
            loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup",
              },
            });
          }}
        />
      </motion.div>
    );
  }, [props.activeTheme, superNarrow, loginWithRedirect]);

  return (
    <CurrencyContext.Provider value={currenciesQuery.data}>
      {!isLoading &&
        location.pathname === "/" &&
        !props.isAuthenticated &&
        landingPageEntryPoints}
      {!compact ? (
        <div
          className={
            !props.isAuthenticated
              ? "menu-container landing-page"
              : popupMenuOpened
              ? "menu-container-small-screens"
              : "menu-container"
          }
        >
          <>
            <React.Suspense fallback={<></>}>{menuRoutes()}</React.Suspense>
            {props.isAuthenticated && (
              <React.Suspense fallback={<></>}>
                {userPopupMenu()}
              </React.Suspense>
            )}
          </>
        </div>
      ) : (
        <>
          {!popupMenuOpened && props.isAuthenticated ? (
            <div
              className={"compact-menu-container"}
              onClick={() => setPopupMenuOpened(!popupMenuOpened)}
            >
              <label className="compact-menu-label">E</label>
            </div>
          ) : (
            <>
              <div
                className={
                  !props.isAuthenticated
                    ? "menu-container landing-page"
                    : "menu-container-small-screens"
                }
              >
                <>
                  <React.Suspense fallback={<></>}>
                    {menuRoutes()}
                  </React.Suspense>
                  {props.isAuthenticated && (
                    <React.Suspense fallback={<></>}>
                      {userPopupMenu()}
                    </React.Suspense>
                  )}
                </>
              </div>
              {props.isAuthenticated && (
                <div
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setPopupMenuOpened(!popupMenuOpened);
                  }}
                  className="invisible-overlay"
                />
              )}
            </>
          )}
        </>
      )}
      {props.isAuthenticated && (
        <>
          {userInfo?.email_verified === false && (
            <Modal
              centered
              size={"lg"}
              opened={emailVerificationModalOpened}
              title="Verify your email"
              onClose={() => {
                setEmailVerificationModalOpened(false);
                setDidPromptEmailVerification(true);
                window.location.reload();
              }}
            >
              <EmailVerificationPopup activeTheme={props.activeTheme} />
            </Modal>
          )}
          {userInfo?.privacy_policy_accepted &&
          userInfo?.terms_of_service_accepted ? (
            <>
              {hasPermissions && (
                <Modal
                  centered
                  size={"lg"}
                  onClose={() => {
                    setModalOpened(false);
                    window.location.reload();
                  }}
                  opened={modalOpened}
                  title="Profile"
                >
                  <ProfileEditor
                    activeTheme={props.activeTheme}
                    setModalOpened={setModalOpened}
                    existingUsername={userInfo?.username ?? undefined}
                  />
                </Modal>
              )}
            </>
          ) : (
            <>
              <Modal
                centered
                size={"lg"}
                onClose={() => {
                  if (userInfo?.privacy_policy_accepted === false) return;
                  setPrivacyModalOpened(false);
                }}
                opened={privacyModalOpened}
                title="We've updated our Privacy Policy"
              >
                <AcceptModalContent
                  loading={acceptingPrivacy}
                  activeTheme={props.activeTheme}
                  onAccept={handleAcceptPrivacy}
                >
                  <PrivacyPolicy />
                </AcceptModalContent>
              </Modal>
              <Modal
                centered
                size={800}
                onClose={() => {
                  if (userInfo?.terms_of_service_accepted === false) return;
                  setTosModalOpened(false);
                }}
                opened={tosModalOpened}
                title="We've updated our Terms of Service"
              >
                <AcceptModalContent
                  loading={acceptingTos}
                  activeTheme={props.activeTheme}
                  onAccept={handleAcceptTos}
                >
                  <TermsOfService />
                </AcceptModalContent>
              </Modal>
            </>
          )}
        </>
      )}
    </CurrencyContext.Provider>
  );
}
